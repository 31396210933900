import Accordion from "react-bootstrap/Accordion";
import { BuildingBlockTableContainerProps } from "../utils/interface/HSM";
import { HUMAN_HEADERS, ROBOTS_HEADERS } from "../utils/HSMUtils";
import { BuildingBlockTable } from "./BuildingBlockTable";

export function BuildingBlockTableContainer({
  activityProps,
  setActivityProps,
  setIsUnsavedChanges,
  isEditable,
  isExpand,
  isHumanMode,
}: BuildingBlockTableContainerProps) {
  const headers = isHumanMode ? HUMAN_HEADERS : ROBOTS_HEADERS;

  return (
    <div className=" col-10 offset-1 ">
      <Accordion defaultActiveKey="0">
        <Accordion.Item
          eventKey="0"
          style={{
            border: "none",
          }}
        >
          <Accordion.Header
            className="d-flex justify-content-start "
            style={{
              border: "0.4px solid #ACACAC",
              borderRadius: "6px",
              height: "40px",
            }}
          >
            <button
              style={{
                marginLeft: "-1rem",
                fontWeight: "500",
                fontSize: "16px",
                padding: "0.5rem",
                color: "black ",
                background: "transparent",
              }}
            >
              Building Blocks
            </button>
          </Accordion.Header>
          <Accordion.Body style={{ padding: "0px" }}>
            {isExpand && (
              <BuildingBlockTable
                headers={headers}
                isEditable={isEditable}
                isHumanMode={isHumanMode}
                activityProps={activityProps}
                setActivityProps={setActivityProps}
                setIsUnsavedChanges={setIsUnsavedChanges}
              />
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
