export const CenteredSpinner = ({ text }: { text?: string }) => {
  return (
    <div className='d-flex flex-column align-items-center'>
      <div className='spinner-border' role='status'>
        {text ? <span className='visually-hidden'>{text}</span> : null}
      </div>
      {text ? (
        <div>
          <strong>{text}</strong>
        </div>
      ) : null}
    </div>
  );
};
