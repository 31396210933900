import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CenteredSpinner } from "./Spinner";
import {
  Order,
  WorkflowProps,
  WorkflowTableProps,
} from "../utils/interface/ADLSPage";
import ADLSPAGETableBody from "./ADLSPAGETableBody";
import ADLSPagination from "./ADLSPagination";
import AdminRowcount from "./AdminRowcount";
import AdminPageSearch from "./AdminPageSearch";
import AdminPageTableHead from "./AdminPageTableHead";
import AdminPAGETableBody from "./AdminPAGETableBody";
import AdminPagination from "./AdminPagination";
import { CleaningServices } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";

export function AdminUserTable(props) {
  const { avatar } = useParams();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof WorkflowProps>("category");
  const [rows, setRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [adlsLoaded, setAdlsLoaded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(10);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    filterRows(text);
  };

  const filterRows = (text) => {
    const lowercasedFilter = text.toLowerCase();
    const filteredData = rows.filter((row) => {
      return (
        row.name.toLowerCase().includes(lowercasedFilter) ||
        row.email.toLowerCase().includes(lowercasedFilter) ||
        row.occupation.toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredRows(filteredData);
  };

  const handleClick = (url) => {
    window.open(url, "_self");
  };

  const calculatePageCount = (totalData, rowsPerPage) => {
    return Math.ceil(totalData / rowsPerPage) === 0
      ? 1
      : Math.ceil(totalData / rowsPerPage);
  };

  useEffect(() => {
    setpageCount(calculatePageCount(filteredRows.length, rowsPerPage));
  }, [rowsPerPage, filteredRows]);

  useEffect(() => {
    var url: string;

    url = `${process.env.REACT_APP_BACKEND_BASE_URI}/users`;

    fetch(url)
      .then((res) => res.json())
      .then((users) => {
        const formattedUsers = users.map((user) => ({
          id: user._id,
          name: user.name,
          email: user.email,
          role: user.role,
          occupation: user.occupation,
          count: user.children ? user.children.length : 0,
        }));

        setRows(formattedUsers);
        setFilteredRows(formattedUsers);
        setAdlsLoaded(true);
      });
  }, [adlsLoaded]);

  return !adlsLoaded ? (
    <div className="flex  justify-center items-center mb-2">
      <CenteredSpinner />
    </div>
  ) : (
    <Box sx={{ width: "100%" }}>
      <div>
        <div className="flex gap-4 align-items-center mb-4">
          <AdminRowcount
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <AdminPageSearch onSearch={handleSearch} />
        </div>

        <div className="mb-2">
          <div className="w-full">
            <table className="min-w-full">
              <AdminPageTableHead />
              <AdminPAGETableBody
                rows={filteredRows}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                handleClick={handleClick}
                setAdlsLoaded={setAdlsLoaded}
                props={props}
              />
            </table>
          </div>
        </div>
        <div className="flex align-items-center justify-center mt-5 mb-5">
          <AdminPagination
            count={pageCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </Box>
  );
}

export default AdminUserTable;
