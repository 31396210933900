import React from "react";
import { TitleInputProps } from "../utils/interface/HSM";

const TitleInput = ({
  title,
  setTitle,
  setIsUnsavedChanges,
}: TitleInputProps) => {
  function onTitleChange(e) {
    if (e.target.value !== title) {
      setTitle(e.target.value);
      setIsUnsavedChanges(true);
    }
  }

  function onBlur(e) {
    if (title === "") {
      setTitle("Untitled workflow");
    }
  }

  return (
    <div className="input-group mb-3">
      <div className="input-group-prepend ml-28">
        <span
          className="input-group-text mr-4 ml-1"
          id="inputGroup-sizing-default"
        >
          Workflow title
        </span>
      </div>
      <input
        type="text"
        placeholder="Untitled workflow"
        className="title-input"
        aria-label="Workflow title"
        aria-describedby="basic-addon2"
        value={title}
        onChange={(e) => onTitleChange(e)}
        onBlur={(e) => onBlur(e)}
      />
    </div>
  );
};

export default TitleInput;
