import { ToastContainer } from "react-toastify";
import AdminCreditTable from "./AdminCreditTable";

function AdminCredits(
  userContext,
  setCursor,
  handleAvatarFilter,
  handleButtonClick
) {
  return (
    <div className="row col-10 offset-1 ">
      <ToastContainer />
      <AdminCreditTable
        userContext={userContext}
        isPrivate={true}
        setCursor={setCursor}
        handleAvatarFilter={handleAvatarFilter}
        handleButtonClick={handleButtonClick}
      />
    </div>
  );
}

export default AdminCredits;
