import { useState } from "react";
import { WorkflowCaregiver } from "../utils/HSMUtils";

export type NewWorkflowButtonProps = {
  text: string;
  btnClass?: string;
  caregiver?: WorkflowCaregiver;
  defaultAvatarId?: string;
  defaultAdl?: string;
  defaultWorkflowName?: string;
  defaultTemplateWorkflowId?: string;
};

const NewWorkflowButton = ({
  text,
  btnClass = "btn btn-success mb-3  ",
  caregiver = WorkflowCaregiver.HUMAN,
  defaultAvatarId,
  defaultAdl,
  defaultWorkflowName,
  defaultTemplateWorkflowId,
}: NewWorkflowButtonProps) => {
  const [hover, setHover] = useState(false);
  function handleClick() {
    let path = `create_workflow?caregiver=${caregiver}`;

    if (defaultAvatarId) {
      path += `&avatar=${defaultAvatarId}`;
    }

    if (defaultAdl) {
      path += `&adl=${defaultAdl}`;
    }

    if (defaultWorkflowName) {
      path += `&activity=${defaultWorkflowName}`;
    }

    if (defaultTemplateWorkflowId) {
      path += `&parent=${defaultTemplateWorkflowId}`;
    }

    window.open(`${window.origin}/${path}`, "_self");
  }

  return (
    <button
      className={btnClass}
      style={{
        color: hover ? "black" : "white",
        backgroundColor: hover ? "white" : "black",
        border: "none",
        borderRadius: "20px",
        padding: "1rem 1rem",
        cursor: "pointer",
        transition: "background-color 0.3s, color 0.3s",
        marginRight: "0px",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
    >
      <div className="btn-contents ">
        <PlusIcon />
        <div className="btn-text ">
          <b>{text}</b>
        </div>
      </div>
    </button>
  );
};

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-4.5 -4.5 24 24"
      width="24"
      fill="currentColor"
    >
      <path d="M8.9 6.9v-5a1 1 0 1 0-2 0v5h-5a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5z"></path>
    </svg>
  );
};

export default NewWorkflowButton;
