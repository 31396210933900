import React from "react";

export const SelectCaregiver = ({
  humanSelected,
  setHumanSelected,
}: {
  humanSelected: boolean;
  setHumanSelected: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div
      className="btn-group d-flex justify-content-center align-items-center"
      role="group"
      aria-label="Select human caregiver"
    >
      <RadioButton
        id="btnHuman"
        name="btnHuman"
        selected={humanSelected}
        onChange={() => setHumanSelected(true)}
      />
      <label
        className={`w-full py-2 px-4 rounded-full ${
          humanSelected
            ? "bg-gray-800 text-white hover:bg-gray-600"
            : "bg-gray-400 text-white hover:bg-gray-600"
        }`}
        htmlFor="btnHuman"
      >
        Human
      </label>

      <RadioButton
        id="btnRobot"
        name="btnHuman"
        selected={!humanSelected}
        onChange={() => setHumanSelected(false)}
      />
      <label
        className={`w-full py-2 px-4 rounded-full ${
          !humanSelected
            ? "bg-gray-800 text-white hover:bg-gray-600"
            : "bg-gray-400 text-white hover:bg-gray-600"
        }`}
        htmlFor="btnRobot"
      >
        Robot
      </label>
    </div>
  );
};

export const RadioButton = ({
  id,
  name,
  selected,
  onChange,
}: {
  id: string;
  name: string;
  selected: boolean;
  onChange: () => void;
}) => {
  return (
    <input
      type="radio"
      className="btn-check w-25"
      name={name}
      id={id}
      autoComplete="off"
      checked={selected}
      onChange={onChange}
    />
  );
};
