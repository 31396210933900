import React, { useEffect, useState } from "react";
import {
  EnhancedTableHeadProps,
  WorkflowProps,
} from "../utils/interface/ADLSPage";

function ADLSPageTableHead(props: EnhancedTableHeadProps) {
  const { order, orderBy, onRequestSort } = props;
  const [userContext, setUserContext] = useState(null);

  useEffect(() => {
    const userContextData = sessionStorage.getItem("user");
    if (userContextData) {
      setUserContext(JSON.parse(userContextData));
    }
  }, []);

  const createSortHandler =
    (property: keyof WorkflowProps) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <thead className="bg-gray-50">
      <tr>
        <th className="px-4 py-3"></th>
        {props.headCells.map((headCell) => {
          if (!userContext && headCell.label === "Status") {
            return null;
          }
          return (
            <th
              key={headCell.id}
              className="px-6 py-3 text-center text-md font-bold text-black tracking-wider"
            >
              {headCell.label}
            </th>
          );
        })}
        <th className="px-4 py-3"></th>
      </tr>
    </thead>
  );
}

export default ADLSPageTableHead;
