import React from "react";
import { NavigationBarProps } from "../Pages/Admin";
import { NavDropdown } from "react-bootstrap";

function AdminNavButton({
  userContext,
  setUserContext,
  handleButtonClick,
}: NavigationBarProps) {
  const hasOccupation = userContext.occupation !== undefined;
  const dropdownTitle =
    userContext.name +
    (hasOccupation ? " (" + userContext.occupation + ")" : "");

  return (
    <NavDropdown title={dropdownTitle} className="text-light">
      <NavDropdown.Item
        href="#action/3.4"
        className="text-dark"
        onClick={() => {
          setUserContext(undefined);
          sessionStorage.removeItem("user");
          handleButtonClick(1);
        }}
      >
        Logout
      </NavDropdown.Item>
    </NavDropdown>
  );
}

export default AdminNavButton;
