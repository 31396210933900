import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA5131iI-YDrSIvTKWt3rVZ5K12fvtsiWM",
  authDomain: "sparcsbox-76be0.firebaseapp.com",
  projectId: "sparcsbox-76be0",
  storageBucket: "sparcsbox-76be0.appspot.com",
  messagingSenderId: "354104223621",
  appId: "1:354104223621:web:958ba819b4836862a3c6ec",
  measurementId: "G-HVE6Y8K1CE",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db, collection, addDoc };
