import { Box } from "@mui/material";
import React from "react";

function AdminWorkflow() {
  return (
    <div className="row   ">
      <Box sx={{ width: "100%" }}>
        <div>
          <div className="mb-2">
            <div className="w-full">
              <table className="min-w-full"> Workflow Coming soon</table>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default AdminWorkflow;
