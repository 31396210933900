import React from "react";
import AdminUserTable from "./AdminUserTable";
import { ToastContainer } from "react-toastify";

function AdminUsers({
  userContext,
  setCursor,
  handleButtonClick,
  handleAvatarFilter,
}) {
  return (
    <div className="row col-10 offset-1 ">
      <ToastContainer />
      <AdminUserTable
        userContext={userContext}
        isPrivate={true}
        setCursor={setCursor}
        handleAvatarFilter={handleAvatarFilter}
        handleButtonClick={handleButtonClick}
      />
    </div>
  );
}

export default AdminUsers;
