import React, { useEffect, useRef, useState } from "react";
import "../HSM.css";
import { StateBoxProps, StateToolsProps } from "../utils/interface/HSM";
import HSMStateOwnership from "./HSMStateOwnership";
import HSMConditionsBox from "./HSMConditionsBox";
import HSMStateTools from "./HSMStateTools";
import ReactPlayer from "react-player";
import { FaExternalLinkAlt } from "react-icons/fa";

export const HSMStateBox = ({
  type,
  text,
  imageLink,
  videoLink,
  setImageLink,
  setVideoLink,
  preconditions,
  setPreconditions,
  postconditions,
  setPostconditions,
  setText,
  ownership,
  setOwnership,
  discussionButtons,
  hideTools = false,
  viewOnlyMode,
  editing,
  deleteSelf,
  showComments,
  showPapers,
  papers,
  onClick,
  setCursorPointer,
  setCursorAuto,
  humanMode,
  onClickDeepout,
  setActivityProps,
}: StateBoxProps) => {
  const [showingConditions, setShowingConditions] = useState(false);
  const textareaRef = useRef(null);

  const onClickTrash = () => {
    deleteSelf();
  };

  const onClickConditions = () => {
    setShowingConditions((prev) => !prev);
  };

  const emptyOnClick = () => {};

  const updateActivityWithLinks = (key: string, value: string) => {
    setActivityProps((prevActivity: any) => {
      const updatedActivity = {
        ...prevActivity,
        [key]: value,
      };
      return updatedActivity;
    });
  };

  let toolsProps: StateToolsProps = {
    onClickComments: showComments,
    onClickPapers: showPapers,
    onClickTrash: onClickTrash,
    onClickDeeper: onClick,
    onClickConditions,
    onClickDeepout,
    onImageFileSelected: async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URI}/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          const imageUrl = data.fileUrl;

          setImageLink(`${process.env.REACT_APP_BACKEND_BASE_URI}${imageUrl}`);
        } else {
          console.error("Error uploading image");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },

    onVideoUrlEntered: (url: string) => {
      setVideoLink(url);
    },
    type: type,
    discussionButtons: discussionButtons,
    editing: editing,
    setCursorAuto: setCursorAuto,
    setCursorPointer: setCursorPointer,
    isHumanMode: humanMode,
    invis: hideTools,
  };

  useEffect(() => {
    if (editing && textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(text.length, text.length);
    }
  }, [editing, text]);

  let content;

  if (editing) {
    let textAreaProps = {
      value: text,
      onChange: (e) => {
        setText(e.target.value);
      },
      ref: textareaRef,
      style: {
        background: "lightgreen",
      },
      className: "HSM-state-text ",
      placeholder: type,
    };
    content = <textarea {...textAreaProps} />;
  } else {
    let spanProps = {
      className: "HSM-state-text",
      onClick: onClick,
      onMouseEnter: setCursorPointer,
      onMouseLeave: setCursorAuto,
      style: {
        whiteSpace: "nowrap",
        overflowY: "auto" as "auto",
      },
    };
    content = (
      <span {...spanProps}>
        <p>{text}</p>
      </span>
    );
  }

  let stateClass;
  let levelClass;

  switch (type) {
    case "activity":
      stateClass = "HSM-state cactivity";
      levelClass = "level 0";
      break;
    case "composite task":
      stateClass = "HSM-state comptask";
      levelClass = "level 1";
      break;
    case "task":
      stateClass = "HSM-state task";
      levelClass = "level 2";
      break;
    case "composite skill":
      stateClass = "HSM-state compskill";
      levelClass = "level 3";
      break;
    case "skill":
      stateClass = "HSM-state skill";
      levelClass = "level 4";
      break;
    default:
      stateClass = "HSM-state";
      levelClass = "";
      break;
  }

  return (
    <div className={"HSM-Wrapper  " + levelClass}>
      <div className={stateClass}>
        <div className="absolute top-0 left-0 ml-4 text-lg text-white"></div>
        <div className="absolute top-0  right-0 mr-4  text-black">
          <HSMStateOwnership
            editing={editing}
            ownership={ownership}
            humanMode={humanMode}
            setOwnership={setOwnership}
          />
        </div>
        <div className="flex flex-col align-items-start mt-2">
          <div className="HSM-state-text">{content}</div>
          <div className="flex align-items-start">
            {imageLink && (
              <div className="image-box mt-2 pb-1 ml-2">
                <img
                  src={imageLink}
                  alt="Uploaded image"
                  className="w-72 h-36 object-cover"
                />
              </div>
            )}
            {videoLink && (
              <>
                <div className="video-box mt-2 ml-2 pb-8 ">
                  <ReactPlayer
                    url={videoLink}
                    controls
                    width="100%"
                    height="auto"
                  />
                </div>
                <button
                  className="bg-gray-500 text-white p-1 my-2 ml-1 rounded"
                  onClick={() => {
                    window.open(videoLink, "_blank");
                  }}
                >
                  <FaExternalLinkAlt />
                </button>
              </>
            )}
          </div>
        </div>
        {!viewOnlyMode && (
          <div className="absolute bottom-0 right-0 mr-xxx4 mb-1 text-white">
            <HSMStateTools {...toolsProps} />
          </div>
        )}
      </div>
      {type === "task" && showingConditions && (
        <HSMConditionsBox
          preconditions={preconditions}
          setPreconditions={setPreconditions}
          postconditions={postconditions}
          setPostconditions={setPostconditions}
          editing={editing}
        />
      )}
    </div>
  );
};

export default HSMStateBox;
