import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../utils/interface/AppInterface";
import { useEffect, useRef, useState } from "react";
import NotificationComponent from "./NotificationComponent";
import CreditComponent from "./CreditComponent";

export type NavigationBarProps = {
  userContext: UserContext;
  setUserContext: (x: any) => void;
};

const NavBarAccountSection = ({
  userContext,
  setUserContext,
}: NavigationBarProps) => {
  const hasOccupation = userContext.occupation !== undefined;
  const dropdownTitle =
    userContext.name +
    (hasOccupation ? " (" + userContext.occupation + ")" : "");
  return (
    <NavDropdown
      title={dropdownTitle}
      id="basic-nav-dropdown"
      className="text-dark"
    >
      <NavDropdown.Item
        href="#action/3.4"
        className="text-dark"
        onClick={() => {
          window.open(`${window.origin}/my_activities`, "_self");
        }}
      >
        My Activities
      </NavDropdown.Item>
      <NavDropdown.Item
        href="#action/3.4"
        className="text-dark"
        onClick={() => {
          setUserContext(undefined);
          localStorage.removeItem("refreshToken");
        }}
      >
        Logout
      </NavDropdown.Item>
    </NavDropdown>
  );
};

const NavBarLoginSection = ({
  setUserContext,
}: {
  setUserContext: (x: any) => void;
}) => {
  const navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const user = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/auth/google`,
        {
          code,
        }
      );

      setUserContext(user.data);
      localStorage.setItem("refreshToken", user.data.refresh_token); // Store the refresh token

      if (!user.data.occupation) {
        navigate("signup", { replace: true });
      }
    },
    flow: "auth-code",
    redirect_uri: "http://52.14.111.215/auth/google/callback",
  });

  return (
    <div className="flex  " style={{ paddingLeft: "1rem" }}>
      <Nav.Link
        className="btn btn-dark  link-light "
        style={{
          width: "80px",
          marginRight: "20px",
          height: "30px",
          padding: "0px",
        }}
        onClick={login}
      >
        Sign in
      </Nav.Link>
    </div>
  );
};

const NavigationBar = ({ userContext, setUserContext }: NavigationBarProps) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const navRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  const handleNavbarToggle = () => {
    setExpanded(!expanded);
  };

  const loggedIn = userContext !== undefined;

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) return;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/auth/google/refresh-token`,
        {
          refreshToken,
        }
      );

      const newuser = {
        ...userContext,
        access_token: response.data.access_token,
      };

      setUserContext(newuser);
    } catch (err) {
      console.error("Failed to refresh access token", err);
    }
  };

  useEffect(() => {
    if (userContext) {
      const intervalId = setInterval(refreshAccessToken, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }
  }, [userContext]);

  return (
    <Container
      fluid
      className="d-lg-flex navbar-custom justify-content-center "
    >
      <Navbar
        expand="lg"
        className=" sticky-top navbar-expand-lg navbar-custom "
        expanded={expanded}
        ref={navRef}
      >
        <Container fluid>
          <Navbar.Brand>
            <Nav.Link
              className="link-dark"
              style={{
                fontWeight: "bold",
                borderRadius: "10px",
                paddingInline: "0.3rem",
              }}
              href="/home"
            >
              SPARCS-Box
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav "
            onClick={handleNavbarToggle}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className=" navbar-nav me-auto">
              <Nav.Link
                className="link-dark"
                href="/home"
                style={{ paddingInline: "0.5rem", borderRadius: "10px" }}
              >
                Home
              </Nav.Link>
              {loggedIn ? (
                <NavDropdown
                  title="Avatars"
                  id="basic-nav-dropdown"
                  className="text-dark"
                  style={{ paddingInline: "0.5rem", borderRadius: "10px" }}
                >
                  <NavDropdown.Item href="/avatars">
                    All Avatars
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/my_avatars">
                    My Avatars
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link
                  className="link-dark"
                  href="/avatars"
                  style={{ paddingInline: "0.5rem", borderRadius: "10px" }}
                >
                  Avatars
                </Nav.Link>
              )}
              <Nav.Link
                className="link-dark"
                href="https://emprise.cs.cornell.edu/sparcs/"
                style={{ paddingInline: "0.5rem", borderRadius: "10px" }}
              >
                SPARCS
              </Nav.Link>
              <Nav.Link
                className="link-dark"
                href="/literature"
                style={{ paddingInline: "0.5rem", borderRadius: "10px" }}
              >
                Robotics Papers
              </Nav.Link>
            </Nav>
            <Nav className=" navbar-login-singup">
              {loggedIn ? (
                <NavBarAccountSection
                  userContext={userContext}
                  setUserContext={setUserContext}
                />
              ) : (
                <NavBarLoginSection setUserContext={setUserContext} />
              )}
            </Nav>
          </Navbar.Collapse>
          {loggedIn ? (
            <div className="ml-3 flex gap-4 ">
              <NotificationComponent userContext={userContext} />
              <CreditComponent />
            </div>
          ) : null}
        </Container>
      </Navbar>
    </Container>
  );
};

export default NavigationBar;
