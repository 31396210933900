import { ObjectId } from "bson";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext, UserOccupation } from "../utils/interface/AppInterface";
import { getStatesCurrentLayer } from "../utils/stateUtils";
import NewWorkflowButton from "./NewWorkFlowButton";
import ViewModeGroup from "./ViewMode";
import { HSMToolBar } from "./HSMToolBar";
import {
  HSMRendererProps,
  HSMViewProps,
  StateBoxProps,
} from "../utils/interface/HSM";
import HSMView from "./HSMView";
import HSMRenderer from "../Pages/HSMRenderer";
import ReadOnlyHSMView from "./ReadOnlyHSMView";
import { WorkflowCaregiver } from "../utils/HSMUtils";

// traverses the json object, storing the uid as templateId and generating a new uid for each state
function templateActivityProps(parentProps: any) {
  if (!parentProps.children) {
    return parentProps;
  }

  const children = [...parentProps.children];

  // for each child, do the update, then call recursively on its list of children
  for (let i = 0; i < children.length; i++) {
    children[i].template = children[i].uid;
    children[i].uid = new ObjectId().toHexString();
    children[i].children = templateActivityProps(children[i]);
  }

  return children;
}

function createHSMFromTemplate(
  activityProps: object,
  userContext: UserContext,
  viewMode: string,
  callBackFn: (value: Response) => void | PromiseLike<void>
) {
  // insert clone (without _id) into edit db
  let templatedProps = {
    ...activityProps,
    template: activityProps["_id"],
    author: userContext.id,
    viewMode: viewMode,
    children: templateActivityProps(activityProps),
  };

  fetch(`${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_edit/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(templatedProps),
  }).then(callBackFn);
}

export type UseAsTemplateButtonProps = {
  activityProps: any;
  userContext: UserContext;
};

export const UseAsTemplateButton = ({
  activityProps,
  userContext,
}: UseAsTemplateButtonProps) => {
  const buttonText = "Use as template";
  const defaultCaregiver =
    userContext && userContext.occupation === UserOccupation.ROBOTICIST
      ? WorkflowCaregiver.ROBOT
      : WorkflowCaregiver.HUMAN;
  const defaultActivityName = activityProps.text;

  return (
    <NewWorkflowButton
      text={buttonText}
      caregiver={defaultCaregiver}
      defaultAvatarId={activityProps.avatar}
      defaultAdl={activityProps.adl}
      defaultWorkflowName={defaultActivityName}
      defaultTemplateWorkflowId={activityProps._id}
    />
  );
};

export type ViewModeProps = {
  isHumanMode: boolean;
  setIsHumanMode: React.Dispatch<React.SetStateAction<boolean>>;
  crumbIds: number[];
  setCrumbIds: React.Dispatch<React.SetStateAction<number[]>>;
};

export type ViewOnlyHSMRendererProps = {
  userContext: UserContext;
  nextUID: () => string;
  setCursor: React.Dispatch<React.SetStateAction<string>>;
};

export const ViewOnlyHSMRenderer = ({
  userContext,
  nextUID,
  setCursor,
}: ViewOnlyHSMRendererProps) => {
  const [showingCommentsFor, setShowingCommentsFor] =
    useState<string>(undefined);
  const [showingPapersFor, setShowingPapersFor] = useState<string>(undefined);

  const { id } = useParams();
  const [activityProps, setActivityProps] = useState<StateBoxProps>(undefined); // for robot
  const [humanActivityProps, setHumanActivityProps] =
    useState<StateBoxProps>(undefined);
  const [templatedLayers, setTemplatedLayers] = useState([]);
  const [crumbIds, setCrumbIds] = useState([]);
  const [layer, setLayer] = useState(); // id of first state in layer
  const [hsmLoaded, setHsmLoaded] = useState(false);

  const [isHumanMode, setIsHumanMode] = useState(
    !userContext || userContext.occupation !== UserOccupation.ROBOTICIST
  );

  function setLayerWrapper(layer) {
    setTemplatedLayers([]);
    setLayer(layer);
  }

  // wrapper for hiding papers everytime comments are shown
  function showCommentsWrapper(id: string) {
    setShowingPapersFor(undefined);

    if (id === showingCommentsFor) {
      // toggle comments off
      setShowingCommentsFor(undefined);
    } else {
      setShowingCommentsFor(id);
    }
  }

  // wrapper for hiding comments everytime papers are shown
  function showPapersWrapper(uid: string) {
    setShowingCommentsFor(undefined);
    if (uid === showingPapersFor) {
      // toggle comments off
      setShowingPapersFor(undefined);
    } else {
      setShowingPapersFor(uid);
    }
  }

  // This method fetches the hsms from the database.
  useEffect(() => {
    async function getHsm() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_edit/${id}`
      );
      const responseHuman = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_edit/${id}`
      );

      if (!response.ok) {
        const message = `An error occured: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const hsmRes = await response.json();

      const hsmHumanRes = !responseHuman.ok
        ? hsmRes
        : await responseHuman.json();

      setHsmLoaded(true);
      setActivityProps(hsmRes);
      setHumanActivityProps(hsmHumanRes);
      setCrumbIds([hsmRes.id]);

      if (hsmRes.children) {
        setLayer(hsmRes.children[0].uid);
      }
    }

    getHsm();

    return;
  }, [hsmLoaded]);

  useEffect(() => {
    // TODO maybe only get templates of the same viewing mode?
    if (crumbIds.length > 1) {
      const getTemplatesParams = {
        _id: activityProps["_id"],
        crumbs: crumbIds,
        layer: getStatesCurrentLayer(activityProps, crumbIds),
      };

      fetch(`${process.env.REACT_APP_BACKEND_BASE_URI}/getTemplates`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(getTemplatesParams),
      }).then((res) => {
        if (res.ok) {
          res.json().then((v) => {
            setTemplatedLayers(v);
          });
        }
      });
    }
  }, [layer]);

  if (!hsmLoaded) {
    return null;
  }

  // if in robot mode, we use the robot workflow, otherwise, we use the human workflow
  let rendererActivityProps = isHumanMode ? humanActivityProps : activityProps;
  let rendererSetActivityProps = isHumanMode
    ? setHumanActivityProps
    : setActivityProps;

  const ViewOnlyHSMRendererToolbar = () => {
    return userContext ? (
      <HSMToolBar>
        <UseAsTemplateButton
          activityProps={rendererActivityProps}
          userContext={userContext}
        />
      </HSMToolBar>
    ) : null;
  };

  const hsmViewProps: HSMViewProps = {
    activityProps: rendererActivityProps,
    setActivityProps: rendererSetActivityProps,
    crumbIds: crumbIds,
    setCrumbIds: setCrumbIds,
    setLayer: setLayerWrapper,
    discussionButtons: true,
    viewOnlyMode: false,
    editing: false,
    setCursor: setCursor,
    nextUID: nextUID,
    setNextUID: () => {},
    setShowingCommentsFor: showCommentsWrapper,
    setShowingPapersFor: showPapersWrapper,
    setIsUnsavedChanges: () => {},
    isHumanMode: isHumanMode,
  };

  let readOnlyHsmViews = templatedLayers.map((v) => {
    return (
      <ReadOnlyHSMView
        info={v[0]}
        statesProps={v.slice(1)}
        humanMode={isHumanMode}
      />
    );
  });

  const hsmRendererProps: HSMRendererProps = {
    userContext: userContext,
    activityId: id,
    activityProps: rendererActivityProps,
    setActivityProps: rendererSetActivityProps,
    showingCommentsFor: showingCommentsFor,
    setShowingCommentsFor: setShowingCommentsFor,
    showingPapersFor: showingPapersFor,
    setShowingPapersFor: setShowingPapersFor,
    crumbIds: crumbIds,
    setCrumbIds: setCrumbIds,
    setLayer: setLayerWrapper,
    setCursor: setCursor,
    toolbar: <ViewOnlyHSMRendererToolbar />,
    children: [<HSMView {...hsmViewProps} />, ...readOnlyHsmViews],
    editing: false,
    isHumanMode: isHumanMode,
    center: () => (
      <ViewModeGroup
        isHumanMode={isHumanMode}
        setIsHumanMode={setIsHumanMode}
        crumbIds={crumbIds}
        setCrumbIds={setCrumbIds}
      />
    ),
  };

  return <HSMRenderer {...hsmRendererProps} />;
};

export default ViewOnlyHSMRenderer;
