import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { PiCoin } from "react-icons/pi";

const BASE_URI = process.env.REACT_APP_BACKEND_BASE_URI;

const CreditComponent = () => {
  const [credits, setCredits] = useState([]);
  const [recentCredits, setRecentCredits] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    console.log(user);
    if (!user?.id) return;

    const fetchCredits = async () => {
      try {
        const response = await axios.get(`${BASE_URI}/hsm/credits/${user?.id}`);
        const sortedCredits = response.data.sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
        setCredits(sortedCredits);
        setRecentCredits(sortedCredits.slice(0, 5));
      } catch (error) {
        console.error("Error fetching credits:", error);
      }
    };

    fetchCredits();
  }, [user?.id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const totalCredits = credits.reduce(
    (total, credit) => total + credit.amount,
    0
  );

  return (
    <div className="relative inline-block">
      <div className="relative cursor-pointer" onClick={toggleDropdown}>
        <PiCoin size={32} className="text-grey-500" />
        {totalCredits > 0 && (
          <div className="absolute -top-1 -right-1 flex items-center justify-center w-6 h-6 bg-red-500 rounded-full">
            <span className="text-white text-xs font-bold">{totalCredits}</span>
          </div>
        )}
      </div>

      {dropdownVisible && recentCredits.length > 0 && (
        <div
          ref={dropdownRef}
          className="absolute mt-2 w-32 bg-white shadow-lg rounded-lg p-4 border border-gray-200"
        >
          <div className="flex flex-col items-center">
            {recentCredits.map((credit, index) => (
              <div key={index} className="mb-2 text-center">
                <span
                  className={`text-lg font-bold ${
                    credit.amount < 0
                      ? "text-red-500"
                      : credit.amount > 0
                      ? "text-green-500"
                      : ""
                  }`}
                >
                  {credit.amount} Credits
                </span>
              </div>
            ))}
            <button
              className="w-24 bg-gray-500 text-white py-1 px-2 text-sm rounded hover:bg-gray-700 mt-2"
              onClick={openModal}
            >
              Read More
            </button>
          </div>
        </div>
      )}

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Credit History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {credits.map((credit, index) => (
            <div
              key={index}
              className="mb-2 p-2 border-b border-gray-300 text-center"
            >
              <span
                className={`text-lg font-bold ${
                  credit.amount < 0
                    ? "text-red-500"
                    : credit.amount > 0
                    ? "text-green-500"
                    : ""
                }`}
              >
                {credit.amount} Credits
              </span>
              <br />
              <span className="text-sm text-gray-500">
                {new Date(credit.timestamp).toLocaleString()}
              </span>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreditComponent;
