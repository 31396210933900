import axios, { AxiosError, AxiosResponse } from 'axios';
import { UserContext } from '../utils/interface/AppInterface';
import { addDoc, collection, db } from '../firebase';

const HTTP_UNAUTHORIZED = 401;
const HTTP_NOT_FOUND = 404;

export const getWorkflowByIdRequest = async (
  id: string,
  handleNotFound?: (err: AxiosError) => void
) => {
  // call getHumanWorkflowById, in the event of 404, call getRobot instead
  const humanWorkflowRequestResponse = await getHumanWorkflowByIdRequest(id);

  if (humanWorkflowRequestResponse) {
    return humanWorkflowRequestResponse;
  } else {
    return await getRobotWorkflowByIdRequest(id, handleNotFound);
  }
};

export const getHumanWorkflowByIdRequest = async (
  id: string,
  handleNotFound?: (err: AxiosError) => void
) => {
  // submit get request to hsm_view_human endpoint
  const path = `/hsm_view_human/${id}`;
  const responseData = (await submitGetRequest(
    path,
    undefined,
    handleNotFound
  )) as AxiosResponse<any, any>;
  return responseData ? responseData.data : null;
};

export const getRobotWorkflowByIdRequest = async (
  id: string,
  handleNotFound?: (err: AxiosError) => void
) => {
  // submit get request to hsm_view endpoint
  const path = `/hsm_view/${id}`;
  const responseData = (await submitGetRequest(
    path,
    undefined,
    handleNotFound
  )) as AxiosResponse<any, any>;
  return responseData ? responseData.data : null;
};

export const createWorkflowRequest = async (
  user: UserContext,
  workflow: object,
  handleAuthError?: (err: AxiosError) => void
) => {
  const path = '/hsm_edit';
  const headers = buildAuthorizedRequestHeader(user);
  const responseData = (await submitPostRequest(
    path,
    workflow,
    headers,
    handleAuthError
  )) as AxiosResponse<any, any>;
  if (responseData && responseData.data) {
    const result = responseData.data;
    
    await addDoc(collection(db, "notifications"), {
      text: `${user.name} created new workflow`,
      category: "new_workflow",
      targeted_user: "admin",
      createdby: user.name,
      avatarId: result.avatar,
    });
    return result;
  } else {
    throw new Error('Workflow creation failed');
  }
};

const submitGetRequest = async (
  path: string,
  handleAuthError?: (err: AxiosError) => void,
  handleNotFound?: (err: AxiosError) => void
) => {
  const response = await axios
    .get(`${process.env.REACT_APP_BACKEND_BASE_URI}${path}`)
    .catch((err: AxiosError) => {
      if (err.response) {
        if (err.response.status === HTTP_UNAUTHORIZED && handleAuthError) {
          handleAuthError(err);
        } else if (err.response.status === HTTP_NOT_FOUND) {
          if (handleNotFound) {
            handleNotFound(err);
          }
          return null;
        }
        console.error('Caught AxiosError: ', err);
      }
    });

  return response;
};

const submitPostRequest = async (
  path: string,
  body?: any,
  config?: any,
  handleAuthError?: (err: AxiosError) => void
) => {
  const response = await axios
    .post(`${process.env.REACT_APP_BACKEND_BASE_URI}${path}`, body, config)
    .catch((err: AxiosError) => {
      if (err.response) {
        if (err.response.status === HTTP_UNAUTHORIZED && handleAuthError) {
          handleAuthError(err);
        }
        console.error('Caught AxiosError: ', err);
      }
    });

  return response;
};

const buildAuthorizedRequestHeader = (
  user: UserContext
): { headers: { Authorization: string } } => {
  return { headers: { Authorization: `Bearer ${user.access_token}` } };
};
