const heading = [
  {
    id: "count",
    label: "#",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "Total credit",
    label: "Total credit",
  },
  {
    id: "Bonus",
    label: "Bonus",
  },
];

function AdminCreditTableHead() {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th className=" py-3"></th>
        {heading.map((headCell) => (
          <th
            key={headCell.id}
            className="px-6 py-3 text-center text-md font-bold text-black tracking-wider"
          >
            {headCell.label}
          </th>
        ))}
        <th className=" py-3"></th>
      </tr>
    </thead>
  );
}

export default AdminCreditTableHead;
