import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { StateBoxProps } from "../utils/interface/HSM";

export type SidebarTextInputProps = {
  placeholder: string;
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
};

export const SidebarTextInput = ({
  placeholder,
  text,
  setText,
}: SidebarTextInputProps) => {
  return (
    <textarea
      className="w-full h-12 p-2 mb-4 border rounded resize-none overflow-y-auto"
      placeholder={placeholder}
      onChange={(e) => setText(e.target.value)}
      value={text}
    />
  );
};

export type SidebarProps = {
  title: string;
  description: string;
  children?: JSX.Element[];
  innerClassName: string;
  textInputs: any[];
  canSubmit: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

export const Sidebar = ({
  title,
  description,
  children = [],
  innerClassName,
  textInputs,
  canSubmit,
  onSubmit,
  onCancel,
}: SidebarProps) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-gray-100 w-50 p-6 rounded-lg shadow-xl">
        <div className="flex justify-between">
          <h2 className="text-lg font-bold">{title}</h2>
          <div className="w-6 h-6 mb-2 rounded-full bg-gray-300 flex items-center justify-center ">
            <button
              onClick={onCancel}
              className="text-gray-800 m hover:text-gray-400 focus:outline-none"
            >
              <RiCloseLine className="h-6 w-6 " />
            </button>
          </div>
        </div>
        <div className={innerClassName}>{children}</div>

        <div className="flex flex-col  items-start ">
          <p> {description}</p>
        </div>
        {textInputs.map((prop, index) => (
          <SidebarTextInput key={index} {...prop} />
        ))}

        <div className="flex justify-end space-x-4">
          <button
            onClick={canSubmit ? onSubmit : null}
            className="px-4 py-2 border border-black rounded-lg text-black hover:scale-105 transition-transform"
          >
            Yes, confirm
          </button>
          <button
            onClick={onCancel}
            className="px-4 py-2 border border-white rounded-lg text-white bg-black hover:scale-105 transition-transform"
          >
            No, cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export type SidebarContentProps = {
  title: string;
  description: string;
  text: string;
  modelInfo: string;
  textAsLink: boolean;
};

export const SidebarContentBox = ({
  title,
  description,
  text,
  modelInfo,
  textAsLink,
}: SidebarContentProps) => {
  const formattedText =
    textAsLink && text.indexOf("http") === -1 ? "http://" + text : text;

  const renderTitle = () => {
    if (textAsLink) {
      return (
        <a target="_blank" href={formattedText}>
          <h4 className="text-sm font-bold">{title}</h4>
        </a>
      );
    } else {
      return <h4 className="text-sm font-bold">{title}</h4>;
    }
  };

  const renderText = () => {
    if (textAsLink) {
      return (
        <p>
          <b>{"Model Information: "}</b>
          {modelInfo === "" ? "None" : modelInfo}
        </p>
      );
    } else {
      return <p>{text}</p>;
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-2 flex-wrap">
        {renderTitle()}
        <span className="text-xs">{description}</span>
      </div>
      <div className=" border-b-2 border-b-800 ">{renderText()}</div>
    </div>
  );
};
export type CommentsSidebarProps = {
  stateProps: StateBoxProps; // props for the state being commented on
  // type: string;
  // stateText: string;
  children?: any[];
  addComment: (comment: string) => void;
  hideComments: () => void;
};

export const CommentsSidebar = ({
  stateProps,
  children = [],
  addComment,
  hideComments,
}: CommentsSidebarProps) => {
  const [commentText, setCommentText] = useState("");
  useEffect(() => {
    setCommentText("");
  }, [stateProps.uid]);

  if (!stateProps) {
    hideComments();
    return null;
  }

  const stateType = stateProps.type;
  const stateText = stateProps.text;
  let textAreaProps: SidebarTextInputProps = {
    placeholder: "Enter Comment...",
    setText: setCommentText,
    text: commentText,
  };

  let sidebarProps: SidebarProps = {
    title: "Comments",
    innerClassName: "h-48 overflow-y-auto bg-gray-400 pl-2",
    description: `Comments for '${stateType}': '${stateText}'`,
    textInputs: [textAreaProps],
    canSubmit: commentText !== "",
    onSubmit: () => {
      addComment(commentText);
      setCommentText("");
    },
    onCancel: hideComments,
  };

  return (
    <Sidebar {...sidebarProps}>
      {children.map((comment, index) => {
        let contentProps: SidebarContentProps = {
          title: comment.author,
          modelInfo: "",
          description: `${comment.occupation} (For: ${comment.stateText})`,
          text: comment.text,
          textAsLink: false,
        };
        return <SidebarContentBox key={index} {...contentProps} />;
      })}
    </Sidebar>
  );
};

export type PapersSidebarProps = {
  stateProps: StateBoxProps;
  children?: any[];
  addPaper: (title: string, url: string, modelInfo: string) => void;
  hidePapers: () => void;
};

export const PapersSidebar = ({
  stateProps,
  children = [],
  addPaper,
  hidePapers,
}: PapersSidebarProps) => {
  const [paperTitle, setPaperTitle] = useState("");
  const [paperUrl, setPaperUrl] = useState("");
  const [modelInfo, setModelInfo] = useState("");
  useEffect(() => {
    setPaperTitle("");
    setPaperUrl("");
    setModelInfo("");
  }, [stateProps.uid]);

  if (!stateProps) {
    hidePapers();
    return null;
  }

  const stateType = stateProps.type;
  const stateText = stateProps.text;
  let titleTextAreaProps: SidebarTextInputProps = {
    placeholder: "Enter paper title...",
    setText: setPaperTitle,
    text: paperTitle,
  };

  let urlTextAreaProps: SidebarTextInputProps = {
    placeholder: "Enter paper URL...",
    setText: setPaperUrl,
    text: paperUrl,
  };

  let modelTextAreaProps: SidebarTextInputProps = {
    placeholder: "(optional) Enter model information...",
    setText: setModelInfo,
    text: modelInfo,
  };

  let sidebarProps: SidebarProps = {
    title: "Related papers",
    innerClassName: "h-48 overflow-y-auto bg-gray-400 p-2",
    description: `Research papers relating to '${stateType}': '${stateText}'`,
    textInputs: [titleTextAreaProps, urlTextAreaProps, modelTextAreaProps],
    canSubmit: paperTitle !== "" && paperUrl !== "",
    onSubmit: () => {
      addPaper(paperTitle, paperUrl, modelInfo);
      setPaperTitle("");
      setPaperUrl("");
    },
    onCancel: hidePapers,
  };

  return (
    <Sidebar {...sidebarProps}>
      {children.map((paper, index) => {
        let contentProps: SidebarContentProps = {
          title: paper.title,
          modelInfo: paper.modelInfo,
          description: `Posted by ${paper.author} (For: ${paper.stateText})`,
          text: paper.url,
          textAsLink: true,
        };
        return <SidebarContentBox key={index} {...contentProps} />;
      })}
    </Sidebar>
  );
};
