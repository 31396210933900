import { useEffect, useState } from "react";
import { getComparator, stableSort } from "../utils/ADLSPageUtils";
import { db, collection, addDoc } from "../firebase";
import axios from "axios";
import { Visibility } from "@mui/icons-material";
import { toast } from "react-toastify";

const ADLSPAGETableBody = ({
  rows,
  order,
  orderBy,
  currentPage,
  rowsPerPage,
  handleClick,
  props,
  setrefresh,
}) => {
  const [startIndex, setstartIndex] = useState(0);
  const [endIndex, setendIndex] = useState(rowsPerPage);
  const [userContext, setUserContext] = useState(null);

  useEffect(() => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    setstartIndex(start);
    setendIndex(end);

    const userContextData = sessionStorage.getItem("user");
    if (userContextData) {
      setUserContext(JSON.parse(userContextData));
    }
  }, [currentPage, rowsPerPage]);

  const handleStatusClick = (row) => {
    handleUpdate(row, !row.visibility, undefined);
  };

  const handleApprovedClick = async (row) => {
    try {
      await handleUpdate(row, undefined, true);
    } catch (error) {
      console.error("Error adding notification or updating document:", error);
    }
  };

  const handleUpdate = async (row, visibility, approved) => {
    const updateData = {
      ...(visibility !== undefined && { visibility }),
      ...(approved !== undefined && { approved }),
    };
    if (Object.keys(updateData).length === 0) {
      alert("No fields to update");
      return;
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/adls_edit/${row.id}`,
        {
          updateData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (approved !== undefined) {
        await addDoc(collection(db, "notifications"), {
          text: `${row.name} Approved`,
          category: "new_workflow",
          targeted_user: row.author,
          createdby: "admin",
          avatarId: row.avatar,
        });
      }
      handleAddCredits(row.author, 10, userContext);
      setrefresh(false);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const saveCommentToDB = async (comment) => {
    try {
      await addDoc(collection(db, "notifications"), {
        text: comment.text,
        category: "comment",
        targeted_user: comment.target,
        createdby: comment.user.role,
      });
    } catch (err) {
      console.error("Error saving comment", err);
    }
  };

  const handleAddCredits = async (userId, amount, user) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm/credits`,
        {
          userId,
          amount,
        }
      );

      if (response.status === 201) {
        toast.success(` ${response.data.message}`);
        const newComment = {
          text: response.data.message,
          target: userId,
          user: user,
          timestamp: new Date(),
        };
        await saveCommentToDB(newComment);
      }
    } catch (error) {
      console.error("Error adding credits:", error);
    }
  };
  const renderApprovedButton = (row) => {
    if (row.approved) {
      return null;
    }
    return (
      <td className="text-center">
        <button
          className="px-2 py-1 bg-green-500 text-white rounded"
          onClick={() => handleApprovedClick(row)}
        >
          Approve
        </button>
      </td>
    );
  };

  return (
    <tbody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(startIndex, endIndex)
        .map((row, index) => (
          <tr
            key={index}
            className="hover:bg-gray-100 cursor-pointer"
            onMouseEnter={() => props.setCursor("pointer")}
            onMouseLeave={() => props.setCursor("auto")}
          >
            <td className="px-4 py-3"></td>
            <td className="text-center">{startIndex + index + 1}</td>
            <td className="text-center">{row.name}</td>
            <td className="text-center">{row.attributes}</td>
            <td className="text-center">{row.viewMode}</td>
            <td className="text-center">{row.category}</td>
            {userContext && (
              <>
                <td className="text-center">
                  <button
                    className="px-2 py-1 bg-blue-500 text-white rounded"
                    onClick={() => handleStatusClick(row)}
                  >
                    {row.status}
                  </button>
                </td>
                {userContext.name === "admin" && renderApprovedButton(row)}
              </>
            )}
            {userContext && userContext.id === row.author ? (
              <td className="text-center">
                <button
                  className="px-2 py-1 bg-yellow-500 text-white rounded"
                  onClick={() => handleClick(row.url)}
                >
                  Edit
                </button>
              </td>
            ) : (
              <td className="text-center">
                <button
                  className="px-2 py-1 bg-gray-500 text-white rounded"
                  onClick={() => handleClick(row.url)}
                >
                  View
                </button>
              </td>
            )}

            {userContext && userContext.name !== "admin" && !row.approved && (
              <td className="text-center">
                <div className="px-2 py-1 bg-red-200 text-white rounded">
                  Unapproved
                </div>
              </td>
            )}

            {userContext && userContext.name !== "admin" && row.approved && (
              <td className="text-center">
                <div className="px-2 py-1"></div>
              </td>
            )}
          </tr>
        ))}
    </tbody>
  );
};

export default ADLSPAGETableBody;
