import { useNavigate, Link } from "react-router-dom";

const Card = ({ imageUrl, title, paragraph }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/avatars");
  };
  const truncatedText = `${paragraph.slice(0, 80)}...`;

  return (
    <div className="card">
      <div className="card bg-light" style={{ width: "auto", height: "auto" }}>
        <img src={imageUrl} className=" mx-5 " />
      </div>
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p
          className=" card-text "
          style={{
            marginBottom: "20px",
          }}
        >
          {truncatedText}
        </p>
        <Link to="/avatars">
          <button
            className="btn bg-dark align-content-center"
            onClick={handleButtonClick}
            style={{ borderRadius: "10px", color: "white", fontSize: "12px" }}
          >
            Read More&nbsp;&nbsp;&rarr;
          </button>{" "}
        </Link>
      </div>
    </div>
  );
};

export default Card;
