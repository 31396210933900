import React, { useState, useRef, useEffect } from "react";
import {
  FaBell,
  FaComment,
  FaUserPlus,
  FaStar,
  FaArrowRight,
  FaPlusCircle,
} from "react-icons/fa";
import { db, collection } from "../firebase";
import { query, where, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { PiNetworkFill } from "react-icons/pi";

const NotificationComponent = ({ userContext }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setnotifications] = useState([]);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const targetedUser =
      userContext?.name === "admin"
        ? "admin"
        : userContext._id
        ? userContext._id
        : userContext.id;

    const q = query(
      collection(db, "notifications"),
      where("targeted_user", "==", targetedUser)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notification = [];

      snapshot.forEach((doc) => {
        notification.push({ id: doc.id, ...doc.data() });
      });

      setnotifications(notification);
    });

    return () => unsubscribe();
  }, [userContext]);

  const handleNotificationClick = async (notification) => {
    try {
      await deleteDoc(doc(db, "notifications", notification.id));
      setnotifications((prevNotifications) =>
        prevNotifications.filter((notif) => notif.id !== notification.id)
      );
      if (
        notification.avatarId &&
        (notification.category === "comment" ||
          notification.category === "new_avatar" ||
          notification.category === "new_workflow")
      ) {
        navigate(`/CommentsPage/${notification.avatarId}`);
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };
  const renderIcon = (type) => {
    switch (type) {
      case "review":
        return <FaStar className="text-yellow-500" />;
      case "comment":
        return <FaComment className="text-blue-500" />;
      case "new_user":
        return <FaUserPlus className="text-green-500" />;
      case "new_avatar":
        return <FaPlusCircle className="text-purple-500" />;
      case "new_workflow":
        return <PiNetworkFill className="text-orange-500" />;
      default:
        return <FaBell />;
    }
  };

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <div className="relative cursor-pointer" onClick={toggleDropdown}>
        <FaBell size={24} />
        <div
          className="absolute top-0 right-0 bg-red-500 text-white rounded-full"
          style={{
            padding: "2px 6px",
            fontSize: "12px",
            transform: "translate(50%, -50%)",
          }}
        >
          {notifications.length}
        </div>
      </div>
      {isOpen && (
        <div className="absolute right-0 p-1 mt-2 w-64 bg-white border border-black rounded-md shadow-lg z-10 max-h-96 overflow-y-auto">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className="relative p-3 border-2 border-gray-500 bg-white hover:bg-gray-300 cursor-pointer mb-2 rounded-lg"
              onClick={() => handleNotificationClick(notification)}
            >
              <div className="flex justify-between items-center">
                <div className="absolute font-bold top-1">
                  {notification.category}
                </div>
                <div className="absolute top-2 right-2">
                  {renderIcon(notification.category)}
                </div>
              </div>
              <div className="text-sm text-gray-600 text-center mb-2 mt-3">
                {notification.text}
              </div>
              <div className="text-xs text-gray-600 absolute bottom-2 left-2">
                By {notification.createdby}
              </div>
              <div className="absolute bottom-2 right-2">
                <FaArrowRight className="text-gray-600" />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationComponent;
