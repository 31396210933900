import { useEffect, useState } from "react";
import { CenteredSpinner } from "../components/Spinner";
import SpacsBox from "../components/HeroSection";
import Card from "../components/AvatorCard";
import { FaPlus } from "react-icons/fa";
import AddAvatar from "../components/AddAvatar";
import { UserContext } from "../utils/interface/AppInterface";
import { Visibility } from "@mui/icons-material";

export const Avatars = ({ userContext }: { userContext: any }) => {
  const [avatars, setAvatars] = useState([]);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [hoveredIcons, setHoveredIcons] = useState(false);
  const [showAvatarCreateModal, setShowAvatarCreateModal] = useState(false);
  const [avatarsLoaded, setAvatarsLoaded] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    async function getAvatars() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatars`
      );

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const avatarsRes = await response.json();

      const avatarsData = avatarsRes
        .filter((avatarObj) => avatarObj.created_by === userContext.id)
        .map((avatarObj) => ({
          title: avatarObj.name,
          _id: avatarObj._id,
          paragraph: avatarObj.description,
          setPlaylistOpen: setPlaylistOpen,
          setVideoUrl: setVideoUrl,
          videoUrl: avatarObj.videos,
          playlistOpen: playlistOpen,
          imageUrl: avatarObj.image,
          status: avatarObj.status,
          measurements: avatarObj.measurements,
          visibility: avatarObj.visibility,
          created_by: avatarObj.created_by,
          user: true,
        }));

      setAvatars(avatarsData);
      setAvatarsLoaded(true);
    }

    if (!avatarsLoaded) {
      getAvatars();
    }
  }, [avatarsLoaded]);

  useEffect(() => {
    if (showSuccessDialog) {
      const timer = setTimeout(() => setShowSuccessDialog(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessDialog]);

  const updateCard = (id) => {
    setAvatars((prevCard) =>
      prevCard.map((avatars) => (avatars.id === id ? { ...avatars } : avatars))
    );
  };

  return !avatarsLoaded ? (
    <CenteredSpinner />
  ) : (
    <div className="col">
      <div className="container-md mb-2">
        <div className="row">
          <SpacsBox
            heading="Lorem ipsum dolor sit amet consectetur. Amet amet et quis."
            buttonTitle="My Avatars"
          />
        </div>
      </div>
      <div className="flex justify-end col-10 ring-offset-4 ml-14 mb-2">
        <button
          className="rounded-lg w-40 h-10 border flex align-items-center"
          onMouseEnter={() => setHoveredIcons(true)}
          onMouseLeave={() => setHoveredIcons(false)}
          style={{ backgroundColor: hoveredIcons ? "white" : "black" }}
          onClick={() => setShowAvatarCreateModal(true)}
        >
          <FaPlus
            className="text-xl mr-2 ml-2"
            style={{ color: hoveredIcons ? "black" : "white" }}
          />
          <div style={{ color: hoveredIcons ? "black" : "white" }}>
            Add New Avatar
          </div>
        </button>
      </div>
      <AddAvatar
        show={showAvatarCreateModal}
        userContext={userContext}
        onHide={() => setShowAvatarCreateModal(false)}
        oncomplete={() => {
          setShowAvatarCreateModal(false);
          setAvatarsLoaded(false);
          setShowSuccessDialog(true);
        }}
      />
      {showSuccessDialog && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl mb-4">Success</h2>
            <p className="mb-4">Avatar created successfully!</p>
          </div>
        </div>
      )}
      <div className="flex flex-wrap gap-y-10 col-10 offset-2 gap-x-5">
        {avatars.map((obj) => (
          <Card
            key={obj._id}
            userContext={userContext}
            {...obj}
            setAvatarsLoaded={setAvatarsLoaded}
          />
        ))}
      </div>
    </div>
  );
};

export default Avatars;
