import { useParams } from "react-router-dom";
import NewWorkflowButton from "../components/NewWorkFlowButton";
import { ADLSPageProps } from "../utils/interface/ADLSPage";
import ADLSPageTable from "../components/ADLSPageTable";
import SpacsBox from "../components/HeroSection";
import { WorkflowCaregiver } from "../utils/HSMUtils";
import { UserOccupation } from "../utils/AppUtlis";
import { useEffect, useState } from "react";

const ADLSPage = ({ userContext, isPrivate, setCursor }: ADLSPageProps) => {
  const { avatar } = useParams();
  const [showButton, setshowButton] = useState(false);
  const [avatarsLoaded, setAvatarsLoaded] = useState(false);

  const defaultCaregiver =
    userContext && userContext.occupation === UserOccupation.ROBOTICIST
      ? WorkflowCaregiver.ROBOT
      : WorkflowCaregiver.HUMAN;

  useEffect(() => {
    async function getAvatars() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${avatar}`
      );

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const avatarObj = await response.json();

      userContext && avatarObj.created_by === userContext.id
        ? setshowButton(true)
        : setshowButton(false);
    }

    if (!avatarsLoaded) {
      getAvatars();
    }
  }, [avatarsLoaded]);

  return (
    <div>
      <div className="container-md">
        <div className="row w-full  ">
          <SpacsBox
            heading="Lorem ipsum dolor sit amet consectetur. Amet amet et quis."
            buttonTitle=" Workflow"
          />
        </div>
      </div>
      <div className="row w-8/12  justify-center   offset-2">
        <ADLSPageTable
          userContext={userContext}
          isPrivate={isPrivate}
          setCursor={setCursor}
        />
      </div>

      {showButton && (
        <div className="d-flex justify-content-center mt-4">
          <NewWorkflowButton
            text="New Workflow"
            btnClass="bg-black text-white p-3  rounded-full"
            caregiver={defaultCaregiver}
            defaultAvatarId={avatar}
          />
        </div>
      )}
    </div>
  );
};

export default ADLSPage;
