import leftArrow from "../imgs/arrow-left.png";
import rightArrow from "../imgs/arrow.png";
import downArrow from "../imgs/arrow-down.png";

import { getNewStateProps, addStateUid } from "./stateUtils";
import { SaveChangesProps, TransitionProps } from "./interface/HSM";

export enum TRANSITION_TYPE {
  BEFORE,
  BETWEEN,
  AFTER,
}

export enum TRANSITION_ORIENTATION {
  LEFT = "arrow-left.png",
  RIGHT = "arrow.png",
  DOWN = "arrow-down.png",
}

export const HUMAN_HEADERS = [
  "User Functionality Model",
  "User Behavior Model",
  "Environment Model",
  "Caregiver Functionality Model",
  "Caregiver Behavior Model",
];

export const ROBOTS_HEADERS = [...HUMAN_HEADERS, "Robot Model"];

export enum RENDERER_MODE {
  EDIT,
  VIEW,
}

export const StateTransition = ({
  type,
  orientation,
  adding,
  predId,
  nextUID,
  crumbIds,
  setCrumbIds,
  setActivityProps,
  setCursor,
  setIsUnsavedChanges,
}: TransitionProps) => {
  const layersBelow = 4 - crumbIds.length;

  const AddIcon = () => {
    let svgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "-2 -2 24 24",
      width: "24",
      fill: "currentColor",
      onClick: () => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          let newStateProps = getNewStateProps(
            nextUID,
            layersBelow,
            setActivityProps,
            crumbIds,
            setCrumbIds,
            setCursor
          );

          return {
            ...prevActivity,
            children: addStateUid(
              prevActivity,
              [...crumbIds],
              predId,
              newStateProps
            ),
          };
        });
      },
    };

    return (
      <svg {...svgProps}>
        <path d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4zm7 11v4a1 1 0 0 1-2 0v-4H5a1 1 0 0 1 0-2h4V5a1 1 0 1 1 2 0v4h4a1 1 0 0 1 0 2h-4z"></path>
      </svg>
    );
  };

  // TODO implement the following classes' styles in CSS
  const divClass =
    orientation === TRANSITION_ORIENTATION.DOWN ? "transitionV" : "transitionH";
  const arrowHeight = orientation === TRANSITION_ORIENTATION.DOWN ? 30 : 15;
  let arrowWidth = orientation === TRANSITION_ORIENTATION.DOWN ? 15 : 30;
  let imgSrc;

  switch (orientation) {
    case TRANSITION_ORIENTATION.DOWN:
      imgSrc = downArrow;
      break;
    case TRANSITION_ORIENTATION.LEFT:
      imgSrc = leftArrow;
      break;
    case TRANSITION_ORIENTATION.RIGHT:
      imgSrc = rightArrow;
      break;
  }

  if (
    orientation != TRANSITION_ORIENTATION.DOWN &&
    type != TRANSITION_TYPE.BETWEEN
  ) {
    // this transition is horizontal and either a head or tail transition
    arrowWidth *= 2;
  }
  const arrowImg = <div />;
  let transitionElts = [];

  if (
    !adding &&
    (type === TRANSITION_TYPE.BEFORE || type === TRANSITION_TYPE.AFTER)
  ) {
    return <td></td>;
  }

  if (!adding) {
    // the user is not in adding mode, so we just return a table
    // cell with a single arrow image in it
    return (
      <td>
        <div className={divClass}>{arrowImg}</div>
      </td>
    );
  }

  if (type != TRANSITION_TYPE.BEFORE) {
    // this transition should have an arrow before the plus button
    transitionElts.push(arrowImg);
  }

  transitionElts.push(<AddIcon />);

  if (type != TRANSITION_TYPE.AFTER) {
    // this transition should have an arrow after the plus button
    transitionElts.push(arrowImg);
  }

  return (
    <td>
      <div className={divClass}>{transitionElts}</div>
    </td>
  );
};

export enum WorkflowCaregiver {
  HUMAN = "human",
  ROBOT = "robot",
}

export const SaveChanges = ({
  savedChangesText,
  isUnsavedChanges,
  onSave,
}: SaveChangesProps) => {
  const SaveIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-3 -3 24 24"
        width="24"
        fill="currentColor"
      >
        <path d="M2 0h11.22a2 2 0 0 1 1.345.52l2.78 2.527A2 2 0 0 1 18 4.527V16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 2v14h14V4.527L13.22 2H2zm4 8h6a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2zm0 2v4h6v-4H6zm7-9a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V4a1 1 0 0 1 1-1zM5 3h5a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 3h3V5H6v1z"></path>
      </svg>
    );
  };
  if (isUnsavedChanges) {
    const buttonClassName = "btn btn-success";
    const buttonText = "Save changes";

    return (
      <button onClick={onSave} className={buttonClassName}>
        <SaveIcon />
        {buttonText}
      </button>
    );
  }

  return <span>{savedChangesText}</span>;
};
