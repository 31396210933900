import { useEffect, useState } from "react";
import { createWorkflow, useDefaultValues } from "../utils/WorkflowUtlis";
import { SelectCaregiver } from "./SelectCaregiver";
import { UserContext } from "../utils/interface/AppInterface";
import SpacsBox from "./HeroSection";
import axios from "axios";
import { CenteredSpinner } from "./Spinner";

export type CreateWorkflowFormProps = {
  user: UserContext;
};

export const CreateWorkflowForm = ({ user }: CreateWorkflowFormProps) => {
  const {
    defaultAvatar,
    defaultADL,
    defaultActivityName,
    defaultCaregiver,
    defaultParent,
  } = useDefaultValues();
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [selectedADL, setSelectedADL] = useState(defaultADL);
  const [activityName, setActivityName] = useState(defaultActivityName);
  const [attributeName, setattributeName] = useState("");
  const [caregiver, setCaregiver] = useState(defaultCaregiver);
  const [parent, setParent] = useState(defaultParent);
  const [adls, setAdls] = useState([]);
  const [avatarsLoaded, setAvatarsLoaded] = useState(false);
  const [templaterobot, settemplaterobot] = useState([]);
  const [templatehuman, settemplatehuman] = useState([]);

  var userData = JSON.parse(sessionStorage.user);
  var accessToken = userData.access_token;

  useEffect(() => {
    async function getAvatars() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${defaultAvatar}`
      );

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const avatarObj = await response.json();

      setSelectedAvatar(avatarObj);
    }

    if (!avatarsLoaded) {
      getAvatars();
    }
  }, [avatarsLoaded]);

  useEffect(() => {
    async function getAvatars() {
      const response1 = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/adls/${defaultAvatar}`
      );
      const response2 = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/adls`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const checkResponse = (response) => {
        if (!response.ok) {
          const message = `An error occurred: ${response?.statusText}`;
          window.alert(message);
          return false;
        }
        return true;
      };

      if (!checkResponse(response1) || !checkResponse(response2)) {
        return;
      }

      const templateRes = await response1.json();

      const extractAndSeparateChildren = (data) => {
        let robotArray = [];
        let humanArray = [];

        data.forEach((item) => {
          if (item.children && item.children.length > 0) {
            item.children.forEach((child) => {
              if (child.viewMode === "robot") {
                robotArray.push(child);
              } else if (child.viewMode === "human") {
                humanArray.push(child);
              }
            });
          }
        });

        return { robotArray, humanArray };
      };
      const { robotArray, humanArray } =
        extractAndSeparateChildren(templateRes);

      const adlsRes = await response2.json();

      const adlsData = adlsRes.map((adlsObj) => ({
        name: adlsObj.name,
        _id: adlsObj._id,
      }));
      settemplatehuman(humanArray);
      setAdls(adlsData);
      settemplaterobot(robotArray);
      setAvatarsLoaded(true);
    }

    if (!avatarsLoaded) {
      getAvatars();
    }
  }, [avatarsLoaded]);

  return !avatarsLoaded ? (
    <CenteredSpinner />
  ) : (
    <div className="col">
      <div className="container-md">
        <div className="row">
          <SpacsBox heading="" buttonTitle="Create Worflow" />
        </div>
      </div>
      <div
        className="CreateWorkflow d-flex align-items-center flex-column"
        style={{ marginTop: "-70px" }}
      >
        <div className="mb-3 w-50">
          <label
            htmlFor="createWorkflowAvatarInput"
            className="form-label mt-3 block text-xs font-medium text-gray-400"
          >
            Avatar
          </label>

          <div
            id="createWorkflowAvatarInput"
            className=" mt-1 p-2 block w-full border border-gray-100 rounded-md bg-gray-100"
            aria-label="Selected avatar"
          >
            {selectedAvatar.name}
          </div>

          <label
            htmlFor="createWorkflowADLInput"
            className="form-label mt-3 block text-xs font-medium text-gray-400"
          >
            ADL
          </label>
          <select
            className="form-select mt-1 py-2 block w-full border border-gray-100 rounded-md"
            aria-label="Select ADL"
            value={selectedADL}
            onChange={(e) => {
              setSelectedADL(e.target.value);
            }}
          >
            {adls.map((adls) => (
              <option key={adls._id} value={adls.name}>
                {adls.name}
              </option>
            ))}
          </select>
          <label
            htmlFor="createWorkflowActivityInput"
            className="form-label mt-3 block text-xs font-medium text-gray-400"
          >
            Activity
          </label>
          <input
            type="text"
            className="form-control mt-1 py-2 block w-full border border-gray-100 rounded-md"
            id="createWorkflowActivityInput"
            value={activityName}
            onChange={(e) => setActivityName(e.target.value)}
          />
          <label
            htmlFor="createWorkflowAttributeInput"
            className="form-label mt-3 block text-xs font-medium text-gray-400"
          >
            Attributes
          </label>
          <input
            type="text"
            className="form-control mt-1 py-2 block w-full border border-gray-100 rounded-md"
            id="createWorkflowActivityInput"
            value={attributeName}
            onChange={(e) => setattributeName(e.target.value)}
          />

          <label
            htmlFor="createWorkflowParentInput"
            className="form-label mt-3 block text-xs font-medium text-gray-400"
          >
            Template Workflow
          </label>
          <select
            className="form-select mt-1 py-2 block w-full border border-gray-100 rounded-md"
            aria-label="Select Template"
            value={parent}
            onChange={(e) => setParent(e.target.value)}
          >
            <option value="">None</option>
            {caregiver
              ? templatehuman
                  .filter((templatehuman) => templatehuman.adl === selectedADL)
                  .map((templatehuman) => (
                    <option
                      key={templatehuman._id}
                      value={templatehuman.template}
                    >
                      {templatehuman.text}
                    </option>
                  ))
              : templaterobot
                  .filter((templaterobot) => templaterobot.adl === selectedADL)
                  .map((templaterobot) => (
                    <option
                      key={templaterobot._id}
                      value={templaterobot.template}
                    >
                      {templaterobot.text}
                    </option>
                  ))}
          </select>

          <label
            htmlFor="createWorkflowActivityInput"
            className="form-label d-block mt-3 block text-xs font-medium text-gray-400 "
          >
            Caregiver
          </label>
          <SelectCaregiver
            humanSelected={caregiver}
            setHumanSelected={setCaregiver}
          />

          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="bg-black text-white p-3  rounded-full mt-5 d-flex justify-content-center"
              onClick={() => {
                createWorkflow(
                  user,
                  selectedAvatar._id,
                  selectedADL,
                  activityName,
                  caregiver,
                  parent,
                  attributeName
                );
              }}
            >
              <PlusIcon />
              Create Workflow
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-4.5 -4.5 24 24"
      width="24"
      fill="currentColor"
    >
      <path d="M8.9 6.9v-5a1 1 0 1 0-2 0v5h-5a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5z"></path>
    </svg>
  );
};
function onWorkflowInserted(newWorkflowResponse: any) {
  throw new Error("Function not implemented.");
}
