import React, { useState } from "react";
import { FaRuler, FaVideo, FaTrash, FaComment, FaGlobe } from "react-icons/fa";
import { PiNetworkFill } from "react-icons/pi";
import { MdApproval } from "react-icons/md";
import ModalVideo from "./modalvideo";
import { CenteredSpinner } from "./Spinner";
import NewWorkflowButton from "./NewWorkFlowButton";
import { WorkflowCaregiver } from "../utils/HSMUtils";
import axios from "axios";
import { UserOccupation } from "../utils/AppUtlis";
import { toast } from "react-toastify";
import { db, collection, addDoc } from "../firebase";

const Card = ({
  imageUrl,
  title,
  paragraph,
  userContext,
  _id,
  videos,
  setPlaylistOpen,
  setVideoUrl,
  videoUrl,
  status,
  measurements,
  setAvatarsLoaded,
  visibility,
  created_by,
  admin,
  user,
  comment,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hoveredIcons, setHoveredIcons] = useState(Array(6).fill(false));
  const [helpBox, setHelpBox] = useState({
    text: "",
    visible: false,
    x: 0,
    y: 0,
  });

  const publicWorkflowsUrl = "/adls/" + _id;
  const CommentPagesUrl = "/CommentsPage/" + _id;
  const imgSrc = imageUrl || `./${_id}.gif`;
  const imgSrc2 = `.${imageUrl}` || `../${_id}.gif`;

  const defaultCaregiver =
    userContext &&
    userContext.occupation &&
    userContext.occupation === UserOccupation.ROBOTICIST
      ? WorkflowCaregiver.ROBOT
      : WorkflowCaregiver.HUMAN;

  const handleMouseEnter = (index, text, event) => {
    const newHoveredIcons = [...hoveredIcons];
    newHoveredIcons[index] = true;
    setHoveredIcons(newHoveredIcons);

    const { clientX: x, clientY: y } = event;
    setHelpBox({ text, visible: true, x, y });
  };

  const handleMouseLeave = (index) => {
    const newHoveredIcons = [...hoveredIcons];
    newHoveredIcons[index] = false;
    setHoveredIcons(newHoveredIcons);

    setHelpBox({ ...helpBox, visible: false });
  };

  const avatarApproval = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${_id}/status`,
        {
          status: "ACCEPTED",
        }
      );
      handleAddCredits(created_by, 10, userContext);
      setAvatarsLoaded(false);
    } catch (error) {
      console.log(error);
      if (error.response) {
      } else {
      }
    }
  };

  const avatarVisibility = async (value) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${_id}/visibility`,
        {
          visibility: value,
        }
      );
      setAvatarsLoaded(false);
    } catch (error) {
      console.log(error);
      if (error.response) {
      } else {
      }
    }
  };

  const deleteAvatar = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${_id}`
      );
      handleAddCredits(created_by, -10, userContext);
      setAvatarsLoaded(false);
    } catch (error) {
      console.log(error);
      if (error.response) {
      } else {
      }
    }
  };

  const saveCommentToDB = async (comment) => {
    try {
      await addDoc(collection(db, "notifications"), {
        text: comment.text,
        category: "comment",
        targeted_user: comment.target,
        createdby: comment.user.role,
      });
    } catch (err) {
      console.error("Error saving comment", err);
    }
  };

  const handleAddCredits = async (userId, amount, user) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm/credits`,
        {
          userId,
          amount,
        }
      );

      if (response.status === 201) {
        toast.success(` ${response.data.message}`);
        const newComment = {
          text: response.data.message,
          target: userId,
          user: user,
          timestamp: new Date(),
        };
        await saveCommentToDB(newComment);
      }
    } catch (error) {
      console.error("Error adding credits:", error);
    }
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const viewPublicWorkflows = () => {
    window.open(publicWorkflowsUrl, "_self");
    sessionStorage.setItem("activeButton", "3");
  };

  const viewcommentpage = () => {
    window.open(CommentPagesUrl, "_self");
  };

  const viewMeasurements = () => {
    window.open(`${measurements}`, "_blank");
  };

  const viewVideos = () => {
    setVideoUrl(videos);
    setPlaylistOpen(true);
    setModalIsOpen(true);
  };
  const truncatedText = expanded ? paragraph : `${paragraph.slice(0, 90)}...`;
  return (
    <div className="card w-80 flex justify-center items-center relative">
      <div className="card bg-light relative">
        {!imgLoaded && <CenteredSpinner />}
        {
          <img
            className="mx-5"
            src={comment ? imgSrc2 : imgSrc}
            style={{ display: imgLoaded ? "block" : "none" }}
            onLoad={() => setImgLoaded(true)}
          />
        }
      </div>
      <div className="card-body">
        <h5 className="card-title mb-2 relative">{title}</h5>
        <p
          className={`card-text ${expanded ? "expanded" : "collapsed"}`}
          style={{
            marginBottom: "20px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {truncatedText}
          <span
            onClick={toggleExpanded}
            className="text-black text-sm font-bold cursor-pointer underline"
          >
            {expanded ? " Readless" : " Readmore"}
          </span>
        </p>

        <div className="flex flex-col space-y-4 mr-2 ml-2">
          <button
            className="rounded-lg w-40 h-10 border flex align-items-center hover:text-white border-black hover:bg-black"
            onMouseEnter={(e) =>
              handleMouseEnter(0, "View detailed measurements", e)
            }
            onMouseLeave={() => handleMouseLeave(0)}
            onClick={viewMeasurements}
          >
            <FaRuler
              className="text-xl mr-2 ml-2"
              style={{ color: hoveredIcons[0] ? "white" : "black" }}
            />
            <div>Measurements</div>
          </button>
          <button
            className="rounded-lg w-40 h-10 border flex align-items-center hover:text-white border-black hover:bg-black"
            onMouseEnter={(e) =>
              handleMouseEnter(1, "Watch mobility videos", e)
            }
            onMouseLeave={() => handleMouseLeave(1)}
            onClick={viewVideos}
          >
            <FaVideo
              className="text-xl mr-2 ml-2"
              style={{ color: hoveredIcons[1] ? "white" : "black" }}
            />
            <span>Mobility Videos</span>
          </button>
          {status !== "CREATED" ? (
            <button
              className="rounded-lg w-40 h-10 border border-black flex align-items-center hover:bg-black hover:text-white"
              onMouseEnter={(e) =>
                handleMouseEnter(2, "View public workflows", e)
              }
              onMouseLeave={() => handleMouseLeave(2)}
              onClick={viewPublicWorkflows}
            >
              <PiNetworkFill
                className="text-xl mr-2 ml-2"
                style={{ color: hoveredIcons[2] ? "white" : "black" }}
              />
              <div>View Workflow</div>
            </button>
          ) : comment && userContext.name === "admin" ? (
            <button
              className="rounded-lg w-40 h-10 border border-black flex align-items-center bg-blue-500 hover:bg-blue-400 hover:text-white"
              onMouseEnter={(e) =>
                handleMouseEnter(5, "Approve this avatar", e)
              }
              onMouseLeave={() => handleMouseLeave(5)}
              onClick={avatarApproval}
            >
              <MdApproval
                className="text-xl mr-2 ml-2"
                style={{ color: hoveredIcons[5] ? "white" : "black" }}
              />
              Approve
            </button>
          ) : (
            <button
              className="rounded-lg w-40 h-10 border border-black flex align-items-center hover:bg-black hover:text-white"
              onMouseEnter={(e) =>
                handleMouseEnter(2, "View public workflows", e)
              }
              onMouseLeave={() => handleMouseLeave(2)}
              // onClick={viewPublicWorkflows}
            >
              <PiNetworkFill
                className="text-xl mr-2 ml-2"
                style={{ color: hoveredIcons[2] ? "white" : "black" }}
              />
              <div>Not approved </div>
            </button>
          )}

          {(admin || user) && status == "CREATED" ? (
            <>
              <button
                className="rounded-lg w-40 h-10 border border-black flex align-items-center hover:bg-black hover:text-white"
                onMouseEnter={(e) =>
                  handleMouseEnter(3, "Comment this avatar", e)
                }
                onMouseLeave={() => handleMouseLeave(3)}
                onClick={viewcommentpage}
              >
                <FaComment
                  className="text-xl mr-2 ml-2"
                  style={{ color: hoveredIcons[3] ? "white" : "black" }}
                />
                Comment
              </button>
            </>
          ) : null}

          {(admin || user) && status !== "CREATED" ? (
            <>
              <button
                className={`rounded-lg w-40 h-10 border border-black flex items-center 
      ${
        !visibility
          ? "bg-green-600 text-white"
          : "hover:bg-black hover:text-white"
      }`}
                onMouseEnter={(e) =>
                  handleMouseEnter(
                    4,
                    visibility ? "Make it private" : "Make it public",
                    e
                  )
                }
                onMouseLeave={() => handleMouseLeave(4)}
                onClick={() => avatarVisibility(!visibility)}
              >
                <FaGlobe
                  className="text-xl mr-2 ml-2"
                  style={{
                    color: hoveredIcons[4]
                      ? "white"
                      : !visibility
                      ? "white"
                      : "black",
                  }}
                />
                {visibility ? "Make it private" : "Make it public"}
              </button>
            </>
          ) : null}

          {admin && status == "CREATED" ? (
            <>
              <button
                className="rounded-lg w-40 h-10 border border-black flex align-items-center bg-blue-500 hover:bg-blue-400 hover:text-white"
                onMouseEnter={(e) =>
                  handleMouseEnter(5, "Approve this avatar", e)
                }
                onMouseLeave={() => handleMouseLeave(5)}
                onClick={avatarApproval}
              >
                <MdApproval
                  className="text-xl mr-2 ml-2"
                  style={{ color: hoveredIcons[5] ? "white" : "black" }}
                />
                Approve
              </button>
            </>
          ) : null}

          {admin ? (
            <>
              <button
                className="rounded-lg w-40 h-10 border border-black flex align-items-center bg-red-600 hover:bg-red-400 hover:text-white"
                onMouseEnter={(e) =>
                  handleMouseEnter(6, "Delete this avatar", e)
                }
                onMouseLeave={() => handleMouseLeave(6)}
                onClick={deleteAvatar}
              >
                <FaTrash
                  className="text-xl mr-2 ml-2"
                  style={{ color: hoveredIcons[6] ? "white" : "black" }}
                />
                Delete
              </button>
            </>
          ) : null}

          {user && (
            <NewWorkflowButton
              text="New Workflow"
              btnClass="btn btn-black"
              caregiver={defaultCaregiver}
              defaultAvatarId={_id}
            />
          )}
        </div>
      </div>
      {/* <HelpBox
        text={helpBox.text}
        visible={helpBox.visible}
        x={helpBox.x}
        y={helpBox.y}
      /> */}
      <ModalVideo
        channel="youtube"
        isOpen={modalIsOpen}
        videoId={videoUrl}
        onClose={() => setModalIsOpen(false)}
      />
    </div>
  );
};

export default Card;
