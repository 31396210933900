import React from "react";
import ModalVideo from "./modalvideo.jsx";
import ModalPDF from "../utils/pdfviewer.jsx";

const Details = ({ videos, setPlaylistOpen, setVideoUrl }) => {
  function viewVideos() {
    // TODO pass link to youtube playlist as prop
    setVideoUrl(videos);
    setPlaylistOpen(true);
    // window.open(videos, '_blank');
  }

  function viewMeasurements() {
    // TODO pass link in as prop
    // TODO: create detail page for the avatars
    window.open(
      "https://drive.google.com/file/d/1Nz2c-PyomhaQVNliZifHNrRDrj8Uls2y/view?usp=sharing",
      "_blank"
    );
  }

  const MeasurementsIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-2 -2 24 24"
        width="24"
        fill="currentColor"
      >
        <path d="M14.95 7.879l-.707-.707a1 1 0 0 1 1.414-1.415l.707.707 1.414-1.414-2.828-2.828L2.222 14.95l2.828 2.828 1.414-1.414L5.05 14.95a1 1 0 0 1 1.414-1.414L7.88 14.95l1.414-1.414-.707-.708A1 1 0 0 1 10 11.414l.707.707 1.414-1.414-1.414-1.414a1 1 0 0 1 1.414-1.414l1.415 1.414 1.414-1.414zM.808 13.536L13.536.808a2 2 0 0 1 2.828 0l2.828 2.828a2 2 0 0 1 0 2.828L6.464 19.192a2 2 0 0 1-2.828 0L.808 16.364a2 2 0 0 1 0-2.828z"></path>
      </svg>
    );
  };

  const VideosIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-2 -6 24 24"
        width="24"
        fill="currentColor"
      >
        <path d="M4 2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm9.98 1.605L16 1.585A2 2 0 0 1 17.414 1H18a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-.586A2 2 0 0 1 16 10.414l-2.02-2.019A4 4 0 0 1 10 12H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h6a4 4 0 0 1 3.98 3.605zM17.415 9H18V3h-.586l-3 3 3 3zM5 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
      </svg>
    );
  };

  return (
    <div className="avatars-buttons">
      <button className="btn btn-light" onClick={() => viewMeasurements()}>
        <div className="btn-contents">
          <MeasurementsIcon />
          <div className="btn-text">
            <b>Measurements</b>
          </div>
        </div>
      </button>
      <button className="btn btn-light" onClick={() => viewVideos()}>
        <div className="btn-contents">
          <VideosIcon />
          <div className="btn-text">
            <b>Mobility Videos</b>
          </div>
        </div>
      </button>
    </div>
  );
};

export default Details;
