import React from "react";
import { StateOwnershipProps } from "../utils/interface/HSM";

const HSMStateOwnership = ({
  editing,
  humanMode,
  ownership,
  setOwnership,
}: StateOwnershipProps) => {
  const hasOwnership = ownership !== "";
  const currentSelection = hasOwnership ? ownership : "No ownership";

  const OwnershipDisplay = () => {
    const className = !ownership || humanMode ? "invis" : "";
    return (
      <b>
        <p className={className}>{!ownership ? "." : ownership}</p>
      </b>
    );
  };

  const OwnershipEdit = () => {
    function handleChange(e) {
      setOwnership(e.target.value);
    }

    if (humanMode) {
      return null;
    }

    return (
      <select
        className="ownership-select"
        id="ownership-select"
        value={currentSelection}
        onChange={(e) => handleChange(e)}
      >
        <option value="">No ownership</option>
        <option value="Caregiver">Caregiver</option>
        <option value="Care recipient">Care recipient</option>
        <option value="Robot">Robot</option>
        <option value="Caregiver-Care recipient">
          Caregiver-Care recipient
        </option>
        <option value="Caregiver-Robot">Caregiver-Robot</option>
        <option value="Care recipient-Robot">Care recipient-Robot</option>
        <option value="Caregiver-Care recipient-Robot">
          Caregiver-Care recipient-Robot
        </option>
      </select>
    );
  };

  return (
    <div className="ownership">
      {editing && <OwnershipEdit />}
      {!editing && <OwnershipDisplay />}
    </div>
  );
};

export default HSMStateOwnership;
