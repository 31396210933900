import React from "react";

const SpacsBox = ({ heading, buttonTitle }) => {
  return (
    <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 ">
      <div className="row justify-content-center ">
        <button
          type="button"
          className="btn rounded-pill pointer-events-none"
          style={{
            backgroundColor: null,
            border: "2px solid grey",
            borderRadius: "20px",
            pointerEvents: "none",
            fontWeight: "bold",
            fontSize: "12px",
            width: "auto",
          }}
        >
          {buttonTitle}
        </button>
      </div>
      <hr className="small" />
      <span className="page-subheading">
        <h3>{heading}</h3>
      </span>
    </div>
  );
};

export default SpacsBox;
