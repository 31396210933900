import { UserContext } from "../utils/interface/AppInterface";
import { ADLSPageTable } from "../components/ADLSPageTable";

type MyActivitiesPageProps = {
  userContext: UserContext;
  setCursor: (x: string) => void;
};
const MyActivitiesPage = ({
  userContext,
  setCursor,
}: MyActivitiesPageProps) => {
  return (
    <div>
      <h1 className="header">My Activities</h1>
      <ADLSPageTable
        userContext={userContext}
        isPrivate={true}
        setCursor={setCursor}
      />
    </div>
  );
};

export default MyActivitiesPage;
