import React from "react";
import {
  StateTransition,
  TRANSITION_ORIENTATION,
  TRANSITION_TYPE,
} from "../utils/HSMUtils";
import {
  HSMViewProps,
  TransitionProps,
  StateBoxProps,
} from "../utils/interface/HSM";
import {
  deleteStateUid,
  getStatesCurrentLayer,
  updatePropertyOfState,
} from "../utils/stateUtils";
import HSMStateBox from "./HSMStateBox";

const HSMView = ({
  activityProps,
  setActivityProps,
  crumbIds,
  setCrumbIds,
  setLayer,
  discussionButtons,
  viewOnlyMode,
  editing,
  setCursor,
  nextUID,
  setShowingCommentsFor,
  setShowingPapersFor,
  setIsUnsavedChanges,
  isHumanMode,
}: HSMViewProps) => {
  const statesProps = getStatesCurrentLayer(activityProps, crumbIds);
  const stateElements = [];

  if (!Array.isArray(statesProps) || statesProps.length === 0) {
    return <h1 className="mx-auto">Workflow coming soon</h1>;
  }

  statesProps.forEach((state, i) => {
    const onClickFunc =
      !state.children ||
      state.children.length === 0 ||
      crumbIds.length === 4 ||
      (isHumanMode && crumbIds.length === 2)
        ? () => {}
        : () => {
            setCrumbIds((prevCrumbIds) => [...prevCrumbIds, state.uid]);
            setLayer(state.children[0].uid);
            setCursor("auto");
          };

    const setCursorPointerFunc =
      !state.children ||
      state.children.length === 0 ||
      crumbIds.length === 4 ||
      (isHumanMode && crumbIds.length === 2)
        ? () => {}
        : () => setCursor("pointer");

    const stateProps = {
      viewOnlyMode,
      text: state.text,
      imageLink: state.imageLink,
      videoLink: state.videoLink,
      setText: (txt) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "text",
            txt
          );
          return updatedActivity;
        });
      },
      setImageLink: (link) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "imageLink",
            link
          );
          return updatedActivity;
        });
      },
      setVideoLink: (link) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "videoLink",
            link
          );
          return updatedActivity;
        });
      },
      setPreconditions: (prec) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "preconditions",
            prec
          );
          return updatedActivity;
        });
      },
      setPostconditions: (postc) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "postconditions",
            postc
          );
          return updatedActivity;
        });
      },
      setOwnership: (owner) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "ownership",
            owner
          );
          return updatedActivity;
        });
      },
      discussionButtons,
      editing,
      setEditing: (editing) => {
        setActivityProps((prevActivity) => {
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "editing",
            editing
          );
          return updatedActivity;
        });
      },
      deleteSelf: () => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          deleteStateUid(updatedActivity, crumbIds, state.uid);
          return updatedActivity;
        });
      },
      showComments: () => setShowingCommentsFor(state.uid),
      showPapers: () => setShowingPapersFor(state.uid),
      onClick: onClickFunc,
      setCursorPointer: setCursorPointerFunc,
      setCursorAuto: () => setCursor("auto"),
      humanMode: isHumanMode,
    };

    stateElements.push(
      <div className=" flex justify-end " key={state.uid}>
        <HSMStateBox
          key={state.uid}
          {...state}
          {...stateProps}
          onClickDeepout={() => {
            if (crumbIds.length > 1) {
              setCrumbIds(crumbIds.slice(0, -1));
            }
          }}
        />
      </div>
    );

    if (editing && i < statesProps.length) {
      const transitionProps: TransitionProps = {
        type: TRANSITION_TYPE.BETWEEN,
        orientation: TRANSITION_ORIENTATION.RIGHT,
        adding: editing,
        predId: state.uid,
        nextUID: nextUID,
        crumbIds: crumbIds,
        setCrumbIds: setCrumbIds,
        setActivityProps: setActivityProps,
        setCursor: setCursor,
        setIsUnsavedChanges: setIsUnsavedChanges,
      };

      stateElements.push(
        <StateTransition key={`transition-${state.uid}`} {...transitionProps} />
      );
    }
  });

  return (
    <div className="w-full max-w-full overflow-x-auto mx-auto">
      <div className="flex flex-col space-y-2  ">{stateElements}</div>
    </div>
  );
};

export default HSMView;
