import { useEffect, useState } from "react";
import { UserContext } from "../utils/interface/AppInterface";

export const LiteraturePage = ({
  userContext,
}: {
  userContext: UserContext;
}) => {
  return null;
};

export default LiteraturePage;
