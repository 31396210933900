import { Navigate, Route, Routes } from "react-router-dom";
import EditableHSMRenderer from "../components/EditableHSMRenderer";
import ViewOnlyHSMRenderer from "../components/ViewOnlyHSMRenderer";
import Avatars from "./Avatars";
import Homepage from "./HomePage";
import Details from "../components/Details";
import ADLSPage from "./ADLSPage";
import LiteraturePage from "./LiteraturePage";
import MyActivitiesPage from "./MyActivitiesPage";
import SignupPage from "./SignUp";
import AdminLogin from "./AdminLogin";
import { CreateWorkflowForm } from "../components/CreateWorkFlowForm";
import Admin from "./Admin";
import MyAvatars from "./MyAvatars";
import CommentsPage from "./CommentsPage";
import AdminNavbar from "../components/AdminNavbar";

function PageRoutes(props) {
  const {
    user,
    setUserWrapper,
    loggedInWithoutOccupation,
    setCursor,
    isLoggedIn,
    rendererProps,
    PublicWorkflowTableProps,
    activeButton,
    handleButtonClick,
  } = props;

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/home" element={<Homepage />} />
      <Route
        path="/admin"
        element={
          isLoggedIn &&
          (user.role === "admin" || user.role === "superadmin") ? (
            <>
              <div className="my-16 ">
                <AdminNavbar
                  userContext={user}
                  setUserContext={setUserWrapper}
                  activeButton={activeButton}
                  handleButtonClick={handleButtonClick}
                />
              </div>
              <Admin
                userContext={user}
                setCursor={setCursor}
                activeButton={activeButton}
                handleButtonClick={handleButtonClick}
              />
            </>
          ) : (
            <>
              <div className="my-16">
                <AdminNavbar
                  userContext={user}
                  setUserContext={setUserWrapper}
                  activeButton={activeButton}
                  handleButtonClick={handleButtonClick}
                />
              </div>
              <AdminLogin
                user={user}
                setUser={setUserWrapper}
                setCursor={setCursor}
                activeButton={activeButton}
                handleButtonClick={handleButtonClick}
              />
            </>
          )
        }
      />
      <Route
        path="/CommentsPage/:avatar"
        element={
          isLoggedIn &&
          (user.name === "admin" || !loggedInWithoutOccupation) ? (
            <CommentsPage userContext={user} setCursor={setCursor} />
          ) : null
        }
      />

      <Route
        path="/edit/:id"
        element={
          !loggedInWithoutOccupation ? (
            <EditableHSMRenderer
              userContext={user}
              nextUID={rendererProps.nextUID}
              setCursor={setCursor}
            />
          ) : (
            <Navigate replace to="landing" />
          )
        }
      />
      <Route
        path="/view/:id"
        element={<ViewOnlyHSMRenderer {...rendererProps} />}
      />
      <Route
        path="/adls/:avatar"
        element={
          <div>
            <ADLSPage {...PublicWorkflowTableProps} />
          </div>
        }
      />
      <Route
        path="/avatars"
        element={
          !loggedInWithoutOccupation ? (
            <Avatars userContext={user} />
          ) : (
            <Navigate replace to="/landing" />
          )
        }
      />
      <Route
        path="/my_avatars"
        element={
          !loggedInWithoutOccupation ? (
            <MyAvatars userContext={user} />
          ) : (
            <Navigate replace to="/landing" />
          )
        }
      />

      <Route
        path="/literature"
        element={
          !loggedInWithoutOccupation ? (
            <LiteraturePage userContext={user} />
          ) : (
            <Navigate replace to="/landing" />
          )
        }
      />
      <Route
        path="/my_activities"
        element={
          isLoggedIn ? (
            !loggedInWithoutOccupation ? (
              <MyActivitiesPage userContext={user} setCursor={setCursor} />
            ) : (
              <Navigate replace to="/landing" />
            )
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
      <Route
        path="/create_workflow"
        element={
          isLoggedIn ? (
            loggedInWithoutOccupation ? (
              <Navigate replace to="/landing" />
            ) : (
              <CreateWorkflowForm user={user} />
            )
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
      <Route
        path="/details/:avatar"
        element={
          !loggedInWithoutOccupation ? (
            <Details
              videos={undefined}
              setPlaylistOpen={undefined}
              setVideoUrl={undefined}
            />
          ) : (
            <Navigate replace to="landing" />
          )
        }
      />
      <Route
        path="/signup"
        element={
          loggedInWithoutOccupation ? (
            <SignupPage user={user} setUser={setUserWrapper} />
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
    </Routes>
  );
}

export default PageRoutes;
