import React, { useEffect, useState } from "react";
import { db, collection, addDoc } from "../firebase";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
interface ValidationErrors {
  name?: string;
  description?: string;
  image?: string;
  videos?: string;
  measurement?: string;
  server?: string;
}
const imageUrl = [
  "./61ff3d3df7e387abdedc64e2.gif",
  "./61ff3d6ef7e387abdedc64e3.gif",
  "./61ff3d7ef7e387abdedc64e4.gif",
  "./61ff3d8af7e387abdedc64e6.gif",
  "./61ff3d8ff7e387abdedc64e7.gif",
  "./61ff3d84f7e387abdedc64e5.gif",
];

const AddAvatar = ({ show, onHide, oncomplete, userContext }) => {
  const [selectedImage, setSelectedImage] = useState(imageUrl[0]);
  const [formData, setFormData] = useState<ValidationErrors>({
    name: "",
    description: "",
    image: "",
    videos: "",
    measurement: "",
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [user, setUser] = useState(null);
  const [showSignUpDialog, setShowSignUpDialog] = useState(false);

  useEffect(() => {
    const userData = sessionStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, [isFormValid]);

  useEffect(() => {
    const isValid =
      formData.name.trim() !== "" &&
      formData.description.trim() !== "" &&
      formData.image.trim() !== "" &&
      formData.videos.trim() !== "" &&
      formData.measurement.trim() !== "";
    setIsFormValid(isValid);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCardClick = (url) => {
    setSelectedImage(url);
    setFormData((prevState) => ({
      ...prevState,
      image: url,
    }));
  };

  const isValidYouTubeUrl = (url) => {
    const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegex.test(url);
  };

  const isValidUrl = (url) => {
    const urlRegex = /^(http|https):\/\/[^\s$.?#].[^\s]*$/;
    return urlRegex.test(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setShowSignUpDialog(true);
      return;
    }

    const validationErrors: ValidationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    }
    if (!formData.description.trim()) {
      validationErrors.description = "Description is required";
    }
    if (!formData.image.trim()) {
      validationErrors.image = "Image is required";
    }
    if (!formData.videos.trim()) {
      validationErrors.videos = "Video URLs are required";
    } else {
      const videoUrls = formData.videos.split(",");
      for (const url of videoUrls) {
        if (!isValidYouTubeUrl(url.trim())) {
          validationErrors.videos =
            "Each video URL must be a valid YouTube URL";
          break;
        }
      }
    }
    if (!formData.measurement.trim()) {
      validationErrors.measurement = "Measurement details are required";
    } else if (!isValidUrl(formData.measurement.trim())) {
      validationErrors.measurement = "Measurement must be a valid URL";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const userData = JSON.parse(sessionStorage.getItem("user"));

    const accessToken = userData.access_token;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            name: formData.name,
            description: formData.description,
            videos: formData.videos,
            image: formData.image,
            measurements: formData.measurement,
            visibility: true,
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        console.log("Avatar created successfully:", result);
        await addDoc(collection(db, "notifications"), {
          text: `${userContext.name} created new avatar`,
          category: "new_avatar",
          targeted_user: "admin",
          createdby: userContext.name,
          avatarId: result._id,
        });
        setFormData({
          name: "",
          description: "",
          image: "",
          videos: "",
          measurement: "",
        });
        setSelectedImage(imageUrl[0]);
        setErrors({});
        oncomplete();
      } else {
        console.error("Error creating avatar:", result.error);
        setErrors({ ...errors, server: result.error });
      }
    } catch (err) {
      console.error("Network error:", err);
      setErrors({
        ...errors,
        server: "Network error occurred. Please try again.",
      });
    }
  };

  return (
    <>
      {show && (
        <div className="fixed z-10 mt-4 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="relative bg-white w-[70vw] rounded-lg border-2 border-black">
              <div className="p-2">
                <div className="flex justify-end items-center">
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={onHide}
                  >
                    Close
                  </button>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 text-white d-flex align-items-top justify-content-center">
                      <div>
                        <div className="container">
                          <div className="row">
                            {imageUrl.map((url, index) => (
                              <div key={index} className="col-md-4 mb-4">
                                <div
                                  className="card bg-light"
                                  style={{
                                    margin: "20px",
                                    cursor: "pointer",
                                    transition:
                                      "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                                    transform:
                                      selectedImage === url
                                        ? "scale(1.05)"
                                        : "scale(1)",
                                    boxShadow:
                                      selectedImage === url
                                        ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                                        : "none",
                                    borderColor:
                                      selectedImage === url ? "blue" : "gray",
                                    borderWidth: "2px",
                                    borderStyle: "solid",
                                  }}
                                  onClick={() => handleCardClick(url)}
                                  onMouseEnter={(e) => {
                                    e.currentTarget.style.transform =
                                      "scale(1.05)";
                                    e.currentTarget.style.borderColor = "blue";
                                    e.currentTarget.style.boxShadow =
                                      "0 4px 8px rgba(0, 0, 0, 0.2)";
                                  }}
                                  onMouseLeave={(e) => {
                                    if (selectedImage !== url) {
                                      e.currentTarget.style.transform =
                                        "scale(1)";
                                      e.currentTarget.style.borderColor =
                                        "gray";
                                      e.currentTarget.style.boxShadow = "none";
                                    }
                                  }}
                                >
                                  <img
                                    src={url}
                                    className="card-img-top"
                                    alt="Card"
                                    style={{ width: "100%", height: "auto" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          {errors.image && (
                            <small className="text-red-500">
                              {errors.image}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 align-items-center justify-content-center">
                      <div>
                        <div className="flex justify-between items-center mb-4">
                          <h2 className="text-2xl font-bold">
                            Create an Avatar
                          </h2>
                        </div>

                        <form onSubmit={handleSubmit} className="max-w mx-auto">
                          <div className="mb-4">
                            <label
                              htmlFor="name"
                              className="block text-xs font-medium text-gray-400"
                            >
                              Name
                            </label>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                                required
                              />

                              <Tooltip
                                title="This is an info tooltip"
                                placement="top-start"
                                arrow
                              >
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </div>

                            {errors.name && (
                              <small className="text-red-500">
                                {errors.name}
                              </small>
                            )}
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="description"
                              className="block text-xs font-medium text-gray-400"
                            >
                              Description
                            </label>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                                required
                              />
                              <Tooltip
                                title="This is an info tooltip"
                                placement="top-start"
                                arrow
                              >
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </div>

                            {errors.description && (
                              <small className="text-red-500">
                                {errors.description}
                              </small>
                            )}
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="image"
                              className="block text-xs font-medium text-gray-400"
                            >
                              Selected Image URL
                            </label>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <p className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md bg-gray-100">
                                {formData.image}
                              </p>
                              <Tooltip
                                title="This is an info tooltip"
                                placement="top-start"
                                arrow
                              >
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="videos"
                              className="block text-xs font-medium text-gray-400"
                            >
                              Video URLs (comma-separated)
                            </label>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <input
                                type="text"
                                name="videos"
                                value={formData.videos}
                                onChange={handleChange}
                                className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                                placeholder="https://www.youtube.com/watch?v=bKDdT_nyP54&list=RDwhB4BvBRtmc&index=9"
                                required
                              />
                              <Tooltip
                                title="nvnnvjkdfdsjjkdfhkjhddjsfhdusfsddsfdshfkldsfeudjkvdkfbdjfksdbfkhdfhdjskfhdsjkfeifefesfdsfdsfefuiecndn jdkshdnjfidsfhsnfkjdf sduvefbsdhfuds f"
                                placement="top-start"
                                arrow
                              >
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            {errors.videos && (
                              <small className="text-red-500">
                                {errors.videos}
                              </small>
                            )}
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="measurement"
                              className="block text-xs font-medium text-gray-400"
                            >
                              Measurement Details (URL)
                            </label>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <input
                                type="text"
                                name="measurement"
                                value={formData.measurement}
                                onChange={handleChange}
                                className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                                placeholder="https://drive.google.com/file/d/1Nz2c-PyomhaQVNliZifHNrRDrj8Uls2y/view?usp=sharing"
                                required
                              />
                              <Tooltip
                                title="dklfasjfdskalfjkdsfjkdsjflk;dsajflkdsjlkfjkldsjfkdsajflkjsa;lfj;dskfj"
                                placement="top-start"
                                arrow
                              >
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            {errors.measurement && (
                              <small className="text-red-500">
                                {errors.measurement}
                              </small>
                            )}
                          </div>
                          <button
                            type="submit"
                            className={`w-full mb-4 py-2 px-4 rounded-full ${
                              isFormValid
                                ? "bg-black text-white"
                                : "bg-gray-400 text-white"
                            }`}
                          >
                            Create Avatar
                          </button>
                          {errors.server && (
                            <small className="text-red-500">
                              {errors.server}
                            </small>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showSignUpDialog && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl mb-4">Please Sign Up</h2>
            <p className="mb-4">You need to sign up to create an avatar.</p>
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded"
              onClick={() => setShowSignUpDialog(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddAvatar;
