import { styled, TableCell } from "@mui/material";
import { HeadCell, Order } from "./interface/ADLSPage";

export const headCells: readonly HeadCell[] = [
  {
    id: "count",
    disablePadding: false,
    label: "#",
  },
  {
    id: "name",
    disablePadding: false,
    label: "Name",
  },
  {
    id: "attributes",
    disablePadding: false,
    label: "Attributes",
  },
  {
    id: "caregiver",
    disablePadding: false,
    label: "caregiver",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
];

export const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "30px",
});

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
