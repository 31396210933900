import React, { useEffect, useState } from "react";
import Card from "./AvatorCard";
import { CenteredSpinner } from "./Spinner";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

function AdminAvatars({
  userContext,
  activeButton,
  filterid,
  setavatarfilter,
}) {
  const [avatars, setAvatars] = useState([]);
  const [newAvatars, setNewAvatars] = useState([]);
  const [allAvatars, setAllAvatars] = useState([]);
  const [avatarsLoaded, setAvatarsLoaded] = useState(false);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [activeButtonInner, setActiveButtonInner] = useState(1);
  const [searchText, setSearchText] = useState(filterid.toLowerCase() || "");

  const buttons = [
    { id: 1, label: "All Avatars" },
    { id: 2, label: "Newly created Avatars" },
  ];

  const handleSearch = (event) => {
    const text = event.target.value.toLowerCase();
    setSearchText(text);
    filterAvatars(text);
  };

  const handleClearSearch = () => {
    setSearchText("");
    filterAvatars("");
    setavatarfilter("");
  };

  const filterAvatars = (text) => {
    const filteredAvatars =
      activeButtonInner === 1
        ? allAvatars.filter(
            (avatar) =>
              avatar.title.toLowerCase().includes(text) ||
              (avatar.created_by &&
                avatar.created_by.toLowerCase().includes(text))
          )
        : newAvatars.filter(
            (avatar) =>
              avatar.title.toLowerCase().includes(text) ||
              (avatar.created_by &&
                avatar.created_by.toLowerCase().includes(text))
          );
    setAvatars(filteredAvatars);
  };
  useEffect(() => {
    if (avatarsLoaded) {
      filterAvatars(searchText);
    }
  }, [activeButtonInner, allAvatars, newAvatars, searchText, avatarsLoaded]);

  useEffect(() => {
    async function getAvatars() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatars`
      );

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const avatarsRes = await response.json();

      const avatarsData = avatarsRes
        .filter((avatarObj) => avatarObj.status === "CREATED")
        .map((avatarObj) => ({
          title: avatarObj.name,
          _id: avatarObj._id,
          paragraph: avatarObj.description,
          setPlaylistOpen: setPlaylistOpen,
          setVideoUrl: setVideoUrl,
          videoUrl: avatarObj.videos,
          playlistOpen: playlistOpen,
          imageUrl: avatarObj.image,
          status: avatarObj.status,
          measurements: avatarObj.measurements,
          admin: true,
          visibility: avatarObj.visibility,
          created_by: avatarObj.created_by,
        }));

      const AllavatarsData = avatarsRes.map((avatarObj) => ({
        title: avatarObj.name,
        _id: avatarObj._id,
        paragraph: avatarObj.description,
        setPlaylistOpen: setPlaylistOpen,
        setVideoUrl: setVideoUrl,
        videoUrl: avatarObj.videos,
        playlistOpen: playlistOpen,
        imageUrl: avatarObj.image,
        status: avatarObj.status,
        measurements: avatarObj.measurements,
        admin: true,
        visibility: avatarObj.visibility,
        created_by: avatarObj.created_by,
      }));

      setAllAvatars(AllavatarsData);
      setNewAvatars(avatarsData);
      activeButtonInner === 1
        ? setAvatars(AllavatarsData)
        : setAvatars(avatarsData);
      setAvatarsLoaded(true);
    }

    if (!avatarsLoaded) {
      getAvatars();
    }
  }, [avatarsLoaded, activeButtonInner]);

  const handleButtonClick = (buttonId) => {
    setActiveButtonInner(buttonId);
  };

  return !avatarsLoaded ? (
    <CenteredSpinner />
  ) : (
    <div>
      <div className="ml-16">
        <div className="flex justify-start items-center w-full ml-3">
          <TextField
            variant="outlined"
            label="Filter"
            value={searchText}
            placeholder="Filter..."
            className="rounded-full"
            inputProps={{ style: { height: "0.2rem" } }}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                borderRadius: "10px",
              },
            }}
          />
        </div>
        {buttons.map((button) => (
          <button
            key={button.id}
            className="btn"
            style={{
              backgroundColor:
                activeButtonInner === button.id ? "black" : "grey",
              color: "white",
              padding: "10px 20px",
              margin: "5px",
              borderRadius: "30px",
              border: "none",
            }}
            onClick={() => handleButtonClick(button.id)}
          >
            {button.label}
          </button>
        ))}
      </div>

      {avatars.length > 0 ? (
        <div className="flex flex-wrap gap-y-10 col-10 ml-20 gap-x-5 mt-4">
          {avatars.map((obj) => (
            <Card
              key={obj._id}
              userContext={userContext}
              {...obj}
              setAvatarsLoaded={setAvatarsLoaded}
            />
          ))}
        </div>
      ) : (
        <div className="flex justify-center font-bold">Nothing to show</div>
      )}
    </div>
  );
}

export default AdminAvatars;
