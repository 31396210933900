import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

const ADLSPageSearch = ({ onSearch }) => {
  const handleSearch = (event) => {
    console.log(event);
    onSearch(event.target.value);
  };

  return (
    <div className="flex flex-row align-items-center gap-4 font-medium">
      entries
      <TextField
        variant="outlined"
        label="Search"
        placeholder="Search..."
        className="rounded-full"
        inputProps={{ style: { height: "0.2rem" } }}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          style: {
            borderRadius: "10px",
          },
        }}
      />
    </div>
  );
};

export default ADLSPageSearch;
