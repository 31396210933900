import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db, collection, addDoc } from "../firebase";

const AdminCreditTableBody = ({
  rows,
  currentPage,
  rowsPerPage,
  handleClick,
  setAdlsLoaded,
  props,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const [creditAmount, setCreditAmount] = useState(10);
  const [endIndex, setEndIndex] = useState(rowsPerPage);
  const BASE_URI = process.env.REACT_APP_BACKEND_BASE_URI;

  const saveCommentToDB = async (comment) => {
    try {
      await addDoc(collection(db, "notifications"), {
        text: comment.text,
        category: "comment",
        targeted_user: comment.user.id,
        createdby: "superadmin",
      });
    } catch (err) {
      console.error("Error saving comment", err);
    }
  };
  useEffect(() => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    setStartIndex(start);
    setEndIndex(end);
  }, [currentPage, rowsPerPage]);

  const handleAddCredits = async (userId, amount, user) => {
    try {
      const response = await axios.post(`${BASE_URI}/hsm/credits`, {
        userId,
        amount,
      });
      console.log(response.data.message);
      if (response.status === 201) {
        toast.success(` ${response.data.message}`);
        const newComment = {
          text: response.data.message,
          user: user,
          timestamp: new Date(),
        };
        await saveCommentToDB(newComment);
        setAdlsLoaded(false);
      }
    } catch (error) {
      console.error("Error adding credits:", error);
    }
  };
  return (
    <>
      <tbody>
        {rows.slice(startIndex, endIndex).map(
          (row, index) =>
            row.role !== "superadmin" && (
              <tr
                key={index}
                className={`cursor-pointer ${
                  index % 2 === 1 ? "bg-gray-200" : ""
                }`}
              >
                <td className="py-3"></td>
                <td className="text-center">{startIndex + index + 1}</td>
                <td className="text-center">{row.name}</td>
                <td className="text-center">{row.email}</td>
                <td className="text-center">{row.totalCredits}</td>

                <td className="text-center">
                  <div className="relative inline-block w-full">
                    {props.userContext.role === "superadmin" ? "" : ""}
                    <div className="flex items-center justify-center mt-2">
                      <Button
                        variant="outline-secondary"
                        onClick={() => handleAddCredits(row.id, -10, row)}
                        size="sm"
                      >
                        -
                      </Button>
                      <span className="mx-2 text-lg font-bold">
                        {creditAmount}
                      </span>
                      <Button
                        variant="outline-secondary"
                        onClick={() => handleAddCredits(row.id, 10, row)}
                        size="sm"
                      >
                        +
                      </Button>
                    </div>
                  </div>
                </td>
              </tr>
            )
        )}
      </tbody>
    </>
  );
};

export default AdminCreditTableBody;
