import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

const AdminPagination = ({ count, currentPage, onPageChange }) => {
  const handleChange = (event, value) => {
    onPageChange(value);
  };
  const { items } = usePagination({
    count: count,
    page: currentPage,
    onChange: handleChange,
  });

  return (
    <div className="flex justify-center mt-4">
      <nav>
        <List className="flex  gap-2 ">
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "…";
            } else if (type === "page") {
              children = (
                <button
                  type="button"
                  style={{
                    padding: "0.5rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    backgroundColor: selected ? "#000000" : "#E0E0E0",
                    borderRadius: "0.8rem",
                    color: selected ? "#ffffff" : "#9E9E9E",
                    fontWeight: selected ? "bold" : "normal",
                  }}
                  onClick={(event) => handleChange(event, page)}
                  {...item}
                >
                  {page}
                </button>
              );
            } else {
              children = (
                <button
                  style={{
                    padding: "0.5rem",
                    color: "#9E9E9E",
                    fontSize: "14px",
                    fontWeight: selected ? "bold" : "normal",
                  }}
                  type="button"
                  onClick={(event) => handleChange(event, page)}
                  {...item}
                >
                  {type}
                </button>
              );
            }

            return <li key={index}>{children}</li>;
          })}
        </List>
      </nav>
    </div>
  );
};

export default AdminPagination;
