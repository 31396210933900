import { useEffect, useState, useRef } from "react";
import CommentTable from "../components/CommentTable";
import { useParams } from "react-router-dom";
import Card from "../components/AvatorCard";
import { Box, TextField, IconButton, useMediaQuery } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { db, collection, addDoc } from "../firebase";

import SpacsBox from "../components/HeroSection";

function CommentsPage({ userContext, setCursor }) {
  const { avatar } = useParams();
  const [avatars, setAvatars] = useState([]);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const [avatarsLoaded, setAvatarsLoaded] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const commentsEndRef = useRef(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${avatar}`
      );
      setComments(response.data);

      scrollToBottom();
    } catch (err) {
      console.error("Error fetching comments", err);
    }
  };

  const saveCommentToDB = async (comment) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/saveComment`,
        comment
      );

      await addDoc(collection(db, "notifications"), {
        text: comment.text,
        category: "comment",
        targeted_user:
          userContext.name === "admin" ? avatars[0].createdby : "admin",
        createdby: userContext.name,
        avatarId: avatar,
      });
      fetchComments();
    } catch (err) {
      console.error("Error saving comment", err);
    }
  };

  const deleteCommentFromDB = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${id}`
      );
      fetchComments();
    } catch (err) {
      console.error("Error deleting comment", err);
    }
  };

  const handleCommentSubmit = async () => {
    const newComment = {
      text: commentText,
      user: userContext.name,
      timestamp: new Date(),
      avatarId: avatar,
    };
    await saveCommentToDB(newComment);

    setCommentText("");
    scrollToBottom();
  };

  const handleDeleteComment = async (id) => {
    await deleteCommentFromDB(id);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleCommentSubmit();
    }
  };

  const scrollToBottom = () => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    async function getAvatars() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${avatar}`
      );

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }
      let avatarsData = [];
      const avatarObj = await response.json();

      if (
        avatarObj.created_by === userContext.id ||
        userContext.name === "admin"
      ) {
        avatarsData = [
          {
            title: avatarObj.name,
            _id: avatarObj._id,
            paragraph: avatarObj.description,
            setPlaylistOpen: setPlaylistOpen,
            setVideoUrl: setVideoUrl,
            videoUrl: avatarObj.videos,
            playlistOpen: playlistOpen,
            imageUrl: avatarObj.image,
            status: avatarObj.status,
            createdby: avatarObj.created_by,
            measurements: avatarObj.measurements,
          },
        ];
      } else {
        console.log("No avatars created by this user.");
      }

      setAvatars(avatarsData);
      setAvatarsLoaded(true);
    }

    if (!avatarsLoaded) {
      getAvatars();
    }

    fetchComments();
  }, [avatarsLoaded, avatar, userContext]);

  return (
    <div>
      <div className="container-md mt-10">
        <div className="row">
          <SpacsBox heading="" buttonTitle="Comments" />
        </div>
      </div>
      <div className="flex flex-wrap gap-5 justify-center w-full">
        <div className="flex flex-wrap w-full md:w-2/12 ">
          {avatars.map((obj) => (
            <Card
              key={obj._id}
              userContext={userContext}
              {...obj}
              setAvatarsLoaded={setAvatarsLoaded}
              comment={true}
            />
          ))}
        </div>
        <div className="w-full md:w-6/12 ">
          <CommentTable
            userContext={userContext}
            isPrivate={true}
            setCursor={setCursor}
          />
        </div>
      </div>

      <div className="flex justify-center mt-5">
        <Box
          sx={{
            width: isMobile ? "100%" : "30%",
            minWidth: "25%",
            height: "400px",
            overflowY: "auto",
            border: "1px solid black",
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {comments.length === 0 ? (
            <p style={{ textAlign: "center" }}>No comments</p>
          ) : (
            comments.map((comment) => {
              console.log(comment._id);
              return (
                <Box
                  key={comment._id}
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    padding: "16px",
                    marginBottom: "8px",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "70%",
                      textAlign: "left",
                    }}
                  >
                    <strong>{comment.user}</strong>
                    <p>{comment.text}</p>
                    <small>
                      {new Date(comment.timestamp).toLocaleString()}
                    </small>
                  </Box>
                  {comment.user === userContext.name && (
                    <IconButton
                      onClick={() => handleDeleteComment(comment._id)}
                      sx={{ marginLeft: "auto", color: "red" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              );
            })
          )}
          <div ref={commentsEndRef} />
          <Box
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              width: "100%",
              marginTop: "auto",
              paddingTop: "16px",
              borderTop: "1px solid #ddd",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleCommentSubmit();
            }}
          >
            <TextField
              label="Write a comment"
              variant="outlined"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              onKeyPress={handleKeyPress}
              fullWidth
            />
            <IconButton
              type="submit"
              sx={{ backgroundColor: "black", color: "white" }}
            >
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default CommentsPage;
