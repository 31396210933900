import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Padding } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 1,
    minWidth: 120,
  },
}));

const AdminRowcount = ({ value, onChange }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    onChange(parseInt(event.target.value, 10));
  };

  return (
    <div className="flex gap-4 font-medium  align-items-center ">
      <label
        htmlFor="rowsPerPage"
        className="form-label mb-0 text-sm font-medium"
      >
        Show
      </label>
      <div className="w-12 ">
        <select
          id="rowsPerPage"
          className="form-select-sm  appearance-auto h-8  "
          style={{
            fontSize: "12px",
            padding: "4px",
            backgroundColor: "#E0E0E0",
          }}
          value={value}
          onChange={handleChange}
        >
          {[10, 20, 50, 100].map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default AdminRowcount;
