import { useEffect, useState } from "react";
import { FaEye, FaTrash, FaUserShield, FaUser } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminPAGETableBody = ({
  rows,
  currentPage,
  rowsPerPage,
  handleClick,
  setAdlsLoaded,
  props,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(rowsPerPage);
  const [hoveredButton, setHoveredButton] = useState(null);

  useEffect(() => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    setStartIndex(start);
    setEndIndex(end);
  }, [currentPage, rowsPerPage]);

  const handleMouseEnter = (index) => {
    setHoveredButton(index);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const toggleUserRole = async (userId, newRole) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/users/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ role: newRole }),
        }
      );

      if (response.ok) {
        setAdlsLoaded(false);
        toast.success(`User role updated successfully to ${newRole}`);
      } else {
        toast.error("Error updating user role");
      }
    } catch (error) {
      console.error("Error updating user role:", error);
      toast.error("An error occurred while updating user role");
    }
  };

  const confirmDelete = (userId) => {
    toast(
      <div>
        <p>Are you sure you want to delete this user?</p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => {
              deleteUser(userId);
              toast.dismiss();
            }}
            className="bg-red-500 text-white px-3 py-1 rounded"
          >
            Yes
          </button>
          <button
            onClick={() => toast.dismiss()}
            className="bg-gray-300 text-black px-3 py-1 rounded"
          >
            No
          </button>
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      }
    );
  };

  const deleteUser = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/users/${userId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        toast.success("User deleted successfully");

        setAdlsLoaded(false);
      } else {
        toast.error("Error deleting user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("An error occurred while deleting the user");
    }
  };

  return (
    <>
      <tbody>
        {rows.slice(startIndex, endIndex).map(
          (row, index) =>
            row.role !== "superadmin" && (
              <tr
                key={index}
                className={`cursor-pointer ${
                  index % 2 === 1 ? "bg-gray-200" : ""
                }`}
              >
                <td className="py-3"></td>
                <td className="text-center">{startIndex + index + 1}</td>
                <td className="text-center">{row.name}</td>
                <td className="text-center">{row.email}</td>
                <td className="text-center">{row.occupation}</td>

                <td className="text-left">
                  <div className="relative inline-block w-full">
                    {props.userContext.role === "superadmin" ? (
                      <select
                        value={row.role}
                        onChange={(e) => toggleUserRole(row.id, e.target.value)}
                        className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 pl-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      >
                        <option value="admin" className="text-wrap">
                          Admin
                        </option>
                        <option value="client" className="text-wrap">
                          Client
                        </option>
                      </select>
                    ) : (
                      <span className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 pl-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
                        {row.role}
                      </span>
                    )}
                    <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center text-gray-500">
                      {row.role === "admin" ? (
                        <FaUserShield color="#50C878" size={18} />
                      ) : (
                        <FaUser color="#53afe2" size={18} />
                      )}
                    </div>
                  </div>
                </td>

                <td className="text-center">{row.count}</td>
                <td className="text-center">
                  <div className="flex justify-center">
                    <button
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      className="rounded-full py-2 px-2 my-1 flex items-center transition-colors duration-200 whitespace-nowrap"
                      style={{
                        backgroundColor:
                          row.count !== 0
                            ? hoveredButton === index
                              ? "gray"
                              : "black"
                            : "gray",
                        color: hoveredButton === index ? "black" : "white",
                      }}
                      disabled={row.count === 0}
                      onClick={() => {
                        props.handleButtonClick(2);
                        props.handleAvatarFilter(row.id);
                      }}
                    >
                      <FaEye
                        className="mr-2"
                        style={{
                          color: hoveredButton === index ? "black" : "white",
                        }}
                      />
                      View Avatars
                    </button>
                    {props.userContext.role === "superadmin" ? (
                      <button
                        onClick={() => confirmDelete(row.id)}
                        className="ml-2 bg-red-500 text-white rounded-full py-1 px-2 my-1 flex items-center"
                      >
                        <FaTrash className="mr-2" />
                        Delete
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
              </tr>
            )
        )}
      </tbody>
    </>
  );
};

export default AdminPAGETableBody;
