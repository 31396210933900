import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { BuildingBlocks } from "../model/BuildingBlocks";

import { BuildingBlockTableProps, StateBoxProps } from "../utils/interface/HSM";

export function BuildingBlockTable({
  headers,
  isEditable,
  isHumanMode,
  activityProps,
  setActivityProps,
  setIsUnsavedChanges,
}: BuildingBlockTableProps) {
  const [previous, setPrevious] = useState([]);
  const [curr, setCurr] = useState([]);

  const transposeData = (headers, data) => {
    return headers.map((header, index) => ({
      header,
      value: data[index],
    }));
  };

  function initBuildingBlocksStateValues(
    activityProps: StateBoxProps,
    isHumanMode: boolean
  ) {
    let newValues = Object.values(activityProps["buildingBlocks"]);

    if (isHumanMode) {
      newValues.pop();
    }

    newValues = newValues.map((value) => {
      if (value == "") {
        return "Undefined";
      } else {
        return value;
      }
    });

    return newValues;
  }

  function setBuildingBlockProperty(
    activityProps: StateBoxProps,
    setActivityProps: React.Dispatch<React.SetStateAction<StateBoxProps>>,
    idx: number,
    value: string
  ) {
    const prevBuildingBlocks = activityProps.buildingBlocks;
    const newBuildingBlocks: BuildingBlocks = {
      userFunctionality:
        idx == 0 ? value : prevBuildingBlocks.userFunctionality,
      userBehavior: idx == 1 ? value : prevBuildingBlocks.userBehavior,
      environmentModel: idx == 2 ? value : prevBuildingBlocks.environmentModel,
      caregiverFunctionality:
        idx == 3 ? value : prevBuildingBlocks.caregiverFunctionality,
      caregiverBehavior:
        idx == 4 ? value : prevBuildingBlocks.caregiverBehavior,
      robotModel: idx == 5 ? value : prevBuildingBlocks.robotModel,
    };
    setActivityProps({ ...activityProps, buildingBlocks: newBuildingBlocks });

    return [
      newBuildingBlocks.userFunctionality,
      newBuildingBlocks.userBehavior,
      newBuildingBlocks.environmentModel,
      newBuildingBlocks.caregiverFunctionality,
      newBuildingBlocks.caregiverBehavior,
      newBuildingBlocks.robotModel,
    ];
  }

  // Initialize the values of the building blocks to
  useEffect(() => {
    const initialValues = initBuildingBlocksStateValues(
      activityProps,
      isHumanMode
    );
    setCurr(initialValues);
    console.log("Initialized building blocks values to: ", initialValues);
  }, [0]); // This guarantees to only run exactly once

  const onChange = (e, idx) => {
    console.log(`onChange called for idx ${idx}`);
    if (previous.length == 0) {
      setPrevious(curr);
    }
    const newVal = e.target.value;

    const newValues = setBuildingBlockProperty(
      activityProps,
      setActivityProps,
      idx,
      newVal
    );
    setIsUnsavedChanges(true);

    setCurr(newValues);
  };
  const transposedData = transposeData(headers, curr);
  return (
    <TableContainer
      style={{
        marginTop: "20px",
        borderRadius: "6px",
        border: "0.4px solid #ACACAC",
      }}
    >
      <Table>
        {transposedData.map((row, idx) => (
          <TableBody
            key={idx}
            style={{
              padding: "0px",
              borderBottom: "0.2px solid #ACACAC",
              marginTop: "20px",
            }}
          >
            <div className="flex flex-col">
              <TableCell
                style={{
                  fontWeight: "600",
                  borderBottom: "none",
                  padding: "10px",
                }}
              >
                {row.header}
              </TableCell>
              {isEditable ? (
                <TableCell
                  style={{
                    fontWeight: "400",
                    padding: "10px",
                    marginTop: "-10px",
                    color: "#606060",
                  }}
                >
                  <TextField
                    value={row.value}
                    onChange={(e) => onChange(e, idx)}
                    size="medium"
                    multiline
                    rows={4}
                    variant="filled"
                  />
                </TableCell>
              ) : (
                <TableCell
                  style={{
                    fontWeight: "400",
                    marginTop: "-10px",
                    padding: "10px",
                    color: "#606060",
                  }}
                >
                  <div
                    style={{
                      maxHeight: "150px",
                      overflow: "auto",
                    }}
                  >
                    {row.value}
                  </div>
                </TableCell>
              )}
            </div>
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
}
