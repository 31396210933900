import { ObjectId } from "bson";

export function nextUID(): string {
  return new ObjectId().toHexString();
}

export enum UserOccupation {
  ROBOTICIST = "Roboticist",
  THERAPIST = "Therapist",
  OCCUPATIONAL_THERAPIST = "Occupational Therapist",
}
