import { ObjectId } from "bson";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "./interface/AppInterface";
import {
  createWorkflowRequest,
  getWorkflowByIdRequest,
} from "../services/WorkflowService";
import { getNewStateProps } from "./stateUtils";
import { Visibility } from "@mui/icons-material";

export function templateActivityProps(parentProps: any): any[] {
  if (!parentProps.children) {
    return parentProps;
  }

  const children = [...parentProps.children];

  // for each child, do the update, then call recursively on its list of children
  for (let i = 0; i < children.length; i++) {
    children[i].template = children[i].uid;
    children[i].uid = new ObjectId().toHexString();
    children[i].children = templateActivityProps(children[i]);
  }

  return children;
}

export function onWorkflowInserted(data: any) {
  window.open(`${window.origin}/edit/` + data._id, "_self");
}

export const useDefaultValues = () => {
  const [urlParams] = useSearchParams();

  const defaultAvatar = urlParams.get("avatar") || undefined;
  const defaultADL = urlParams.get("adl") || "Dressing";
  const defaultActivityName = urlParams.get("activity") || undefined;
  const defaultCaregiver =
    urlParams.get("caregiver") === "human"
      ? true
      : urlParams.get("caregiver") === "robot"
      ? false
      : true;
  const defaultParent = urlParams.get("parent") || undefined;

  return {
    defaultAvatar,
    defaultADL,
    defaultActivityName,
    defaultCaregiver,
    defaultParent,
  };
};

export async function createWorkflow(
  user: UserContext,
  avatar: string,
  adl: string,
  activity: string,
  caregiver: boolean,
  parent: string,
  attributeName: string
) {
  const targetViewMode = caregiver ? "human" : "robot";
  const parentProps = parent ? await getWorkflowByIdRequest(parent) : {};

  const newWorkflowProps = !parent
    ? getNewStateProps(
        () => new ObjectId().toHexString(),
        4,
        (x) => {},
        [],
        (x) => {},
        (x) => {}
      )
    : {};

  const templatedPropsChildren = parent
    ? templateActivityProps(parentProps)
    : newWorkflowProps.children;

  const templatedProps = {
    ...JSON.parse(JSON.stringify(parentProps)),
    template: parent,
    id: new ObjectId().toHexString(),
    children: templatedPropsChildren,
    author: user.id,
    avatar: avatar,
    adl: adl,
    text: activity,
    attribute: attributeName,
    approved: false,
    visibility: false,
    viewMode: targetViewMode,
  };

  const newWorkflowResponse = await createWorkflowRequest(user, templatedProps);

  onWorkflowInserted(newWorkflowResponse);
}
