import React from "react";
import { BCNavProps } from "../utils/interface/HSM";
import { BiSolidRightArrow } from "react-icons/bi";

const BCNav: React.FC<BCNavProps> = ({
  crumbIds,
  setCrumbIds,
  setLayer,
  activityProps,
  onMouseEnterCrumb,
  onMouseLeaveCrumb,
}) => {
  function onClickActivities() {
    window.open(`${window.origin}/adls/` + activityProps.avatar, "_self");
  }

  const crumbElts: React.ReactNode[] = [
    <a
      key="activities"
      className="bcLink"
      onMouseEnter={onMouseEnterCrumb}
      onMouseLeave={onMouseLeaveCrumb}
      onClick={onClickActivities}
    >
      Activities
    </a>,
  ];
  let currentProps = activityProps;

  for (let i = 0; i < crumbIds.length; i++) {
    const lastCrumb = i === crumbIds.length - 1;
    let anchorProps: any = {
      key: i,
      onMouseEnter: onMouseEnterCrumb,
      onMouseLeave: onMouseLeaveCrumb,
      onClick: () => {
        setCrumbIds((prevCrumbs) => prevCrumbs.slice(0, i + 1));
        setLayer(i.toString());
      },
    };

    if (lastCrumb) {
      anchorProps = { ...anchorProps, className: "activeBreadCrumb" };
    }

    crumbElts.push(
      <React.Fragment key={`separator-${i}`}>
        <span
          style={{
            marginRight: "5px",
            marginLeft: "5px",
            display: "inline-flex ",
            alignItems: "center",
          }}
        >
          <BiSolidRightArrow style={{ fontSize: "0.7em" }} />
        </span>
        <a className="bcLink" {...anchorProps}>
          {currentProps.text}
        </a>
      </React.Fragment>
    );

    if (!lastCrumb) {
      for (let j = 0; j < currentProps.children.length; j++) {
        if (currentProps.children[j].uid === crumbIds[i + 1]) {
          currentProps = currentProps.children[j];
        }
      }
    }
  }

  return <div className="breadCrumbNav">{crumbElts}</div>;
};

export default BCNav;
