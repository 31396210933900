import React from "react";
import { ReadOnlyHSMViewProps, TransitionProps } from "../utils/interface/HSM";
import {
  StateTransition,
  TRANSITION_ORIENTATION,
  TRANSITION_TYPE,
} from "../utils/HSMUtils";
import HSMStateBox from "./HSMStateBox";

const ReadOnlyHSMView = ({
  statesProps,
  humanMode,
  info,
}: ReadOnlyHSMViewProps) => {
  let stateElements = [];
  let cols = Math.min(1, statesProps.length);
  let rows = Math.ceil(statesProps.length / cols);
  let predId = undefined; // initialize the predecessor ID to -1

  // cols columns for the states, cols-1 columns for the transitions,
  // and 1 column at beginning and end of each row
  let htmlCols = cols + (cols - 1) + 2;

  for (let i = 0; i < rows; i++) {
    // the orientation of the arrow AFTER this state
    // even-indexed rows flow to the right, while odd-indexed rows flow left
    let arrowHOrientation = TRANSITION_ORIENTATION.RIGHT;
    // i % 2 === 0 ? TRANSITION_ORIENTATION.RIGHT : TRANSITION_ORIENTATION.LEFT;

    // retrieve the states that go in the current row (i)
    let statesThisRow = statesProps.slice(
      i * cols,
      Math.min(statesProps.length, i * cols + cols)
    );
    let tdsThisRow = [];

    // determine what the first cell of the row should be (either empty or
    // contains a head or tail transition)
    if (i === 0) {
      tdsThisRow.push(<td></td>); // added this today
    } else {
      // the first element of any row (except the first row) should contain the tail transition only
      // if the row is at max capacity AND the row index is odd AND it is the final row in the table.
      // otherwise, the first cell is empty
      if (statesThisRow.length === cols && i % 2 === 1 && i === rows - 1) {
        // this row is at max capacity and odd-indexed.

        tdsThisRow.push(<td></td>); // added this today
      } else {
        // this row not at max capacity or its even-indexed, so first cell is empty

        tdsThisRow.push(<td></td>);
      }
    }

    for (let j = 0; j < statesThisRow.length; j++) {
      let stateProps = {
        // type: getStateTypeFromLayers(3 - crumbIds.length),
        viewOnlyMode: true,
        discussionButtons: false,
        editing: false,
        hideTools: true,
        humanMode: humanMode,
      };

      let td = (
        <td>
          <HSMStateBox {...statesProps[i * cols + j]} {...stateProps} />
        </td>
      );

      tdsThisRow.push(td);
      predId = statesProps[i * cols + j].uid;

      // each state (except the last of the row) gets a transition after it
      if (j < statesThisRow.length - 1) {
        // must draw an in-between transition
        const currentPredId = predId;
        let transitionProps: TransitionProps = {
          type: TRANSITION_TYPE.BETWEEN,
          orientation: arrowHOrientation,
          adding: false,
          predId: statesProps[i * cols + j].uid,
          nextUID: () => undefined,
          crumbIds: [],
          setCrumbIds: () => {},
          setActivityProps: () => {},
          setCursor: () => {},
          setIsUnsavedChanges: () => {},
        };

        tdsThisRow.push(<StateTransition {...transitionProps} />);
      }
    }

    // if this row is not at max capacity, add the tail transition and empty cells
    // for the 'ghost' states that should be there. this keeps the table aligned properly

    if (statesThisRow.length < cols) {
      // not at max capacity. add tail transition and empty cells. also add empty end cell
      // we know the end cell is empty because the tail transition occurs earlier in the row
      const currentPredId = predId;
      let numEmptyCells = 2 * (cols - statesThisRow.length) - 1;
      tdsThisRow.push(<td></td>); // i added this today 2/20
      tdsThisRow.push(<td colSpan={numEmptyCells}></td>);
      tdsThisRow.push(<td></td>);
    } else {
      // this row is at max capacity.
      // if the final row at max capacity is even-indexed,
      // we let the end cell be the tail transition. otherwise, we leave the end cell empty.
      if (i % 2 == 0 && i === rows - 1) {
        const currentPredId = predId;

        tdsThisRow.push(<td></td>); // i added this today
      } else {
        // we leave the end cell empty for odd rows
        tdsThisRow.push(<td></td>);
      }
    }

    if (i % 2 === 1) {
      // reverse every other row to get the spiral state machine effect
      // however, we do not want to touch the begin and end cells.
      tdsThisRow = [
        tdsThisRow[0],
        ...tdsThisRow.slice(1, tdsThisRow.length - 1).reverse(),
        tdsThisRow[tdsThisRow.length - 1],
      ];
    }

    stateElements.push(<tr key={i}>{tdsThisRow}</tr>);

    const currentPredId = predId;
    let transitionProps: TransitionProps = {
      type: TRANSITION_TYPE.BETWEEN,
      orientation: TRANSITION_ORIENTATION.DOWN,
      adding: false,
      predId: currentPredId,
      nextUID: () => undefined,
      crumbIds: [],
      setCrumbIds: () => {},
      setActivityProps: () => {},
      setCursor: () => {},
      setIsUnsavedChanges: () => {},
    };

    if (i % 2 == 0 && i < rows - 1) {
      // we need a down arrow in the column right before the end cell
      stateElements.push(
        <tr key={-i - 1}>
          <td colSpan={htmlCols - 2}></td>
          <StateTransition {...transitionProps} />
          <td></td>
        </tr>
      );
    } else if (i % 2 == 1 && i < rows - 1) {
      // we need a down arrow in the column right after the begin cell
      stateElements.push(
        <tr key={-i - 1}>
          <td></td>
          <StateTransition {...transitionProps} />
          <td colSpan={htmlCols - 2}></td>
        </tr>
      );
    }
  }

  // TODO rename to HSMView
  return (
    <div className="template">
      <h3>
        Submitted by {info.author.name} ({info.author.occupation})
      </h3>
      <table className="HSMView">
        <tbody>{stateElements}</tbody>
      </table>
    </div>
  );
};

export default ReadOnlyHSMView;
