import React, { useState } from "react";
import Admin from "./Admin";

const LoginScreen = ({
  user,
  setUser,
  setCursor,
  activeButton,
  handleButtonClick,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URI}/users/superadmin`)
      .then((res) => res.json())
      .then((users) => {
        if (users.length > 0) {
          const firstSuperAdmin = users[0];
          console.log(users);
          if (
            email === firstSuperAdmin.email &&
            password === firstSuperAdmin.password
          ) {
            setIsLoggedIn(true);
            setUser(firstSuperAdmin);
            setError("");
          } else {
            setError("Invalid credentials. Please try again.");
          }
        }
      });
  };

  if (isLoggedIn) {
    return (
      <Admin
        userContext={user}
        setCursor={setCursor}
        activeButton={activeButton}
        handleButtonClick={handleButtonClick}
      />
    );
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" col-md-6  text-white d-flex align-items-top justify-content-center">
          <div>
            <img src="/Signup.svg" alt="" />
          </div>
        </div>
        <div className="col-md-4 align-items-center justify-content-center">
          <h2 className="text-2xl font-bold flex justify-between items-center mb-4 ">
            Log into admin panel
          </h2>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label
                htmlFor="email"
                className="block text-xs font-medium text-gray-400"
              >
                Email:
              </label>
              <input
                type="email"
                className="form-control mt-2 py-2 block w-full border border-gray-100 rounded-md"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="password"
                className="block text-xs font-medium text-gray-400"
              >
                Password:
              </label>
              <input
                type="password"
                className="form-control mt-2 py-2 block w-full border border-gray-100 rounded-md"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            <button
              type="submit"
              className="w-full bg-gray-400 text-white py-2 px-4 rounded-full mt-4"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
