import React, { useCallback } from "react";

const ViewModeGroup = ({
  isHumanMode,
  setIsHumanMode,
  crumbIds,
  setCrumbIds,
}) => {
  const handleHumanModeChange = useCallback(() => {
    setIsHumanMode(true);

    if (crumbIds.length >= 3) {
      setCrumbIds([crumbIds[0]]);
    }
  }, [crumbIds, setCrumbIds, setIsHumanMode]);

  const handleRobotModeChange = useCallback(() => {
    setIsHumanMode(false);
  }, [setIsHumanMode]);

  const renderLabel = (id, label, isSelected) => (
    <label
      className={`inline-block px-4 py-2 rounded-full cursor-pointer transition-colors ${
        isSelected
          ? "bg-black text-white"
          : "bg-white text-black border border-gray-300"
      }`}
      htmlFor={id}
    >
      {label}
    </label>
  );

  return (
    <div className="viewmode">
      <div className="btn-group flex gap-2">
        <input
          type="radio"
          className="btn-check"
          name="options"
          id="humanMode"
          autoComplete="off"
          checked={isHumanMode}
          onChange={handleHumanModeChange}
        />
        {renderLabel("humanMode", "Human caregiver", isHumanMode)}

        <input
          type="radio"
          className="btn-check"
          name="options"
          id="robotMode"
          autoComplete="off"
          checked={!isHumanMode}
          onChange={handleRobotModeChange}
        />
        {renderLabel("robotMode", "Robot caregiver", !isHumanMode)}
      </div>
    </div>
  );
};

export default ViewModeGroup;
