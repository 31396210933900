export type EditModeButtonProps = {
  inEditMode: boolean;
  setInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditModeButton = ({
  inEditMode,
  setInEditMode,
}: EditModeButtonProps) => {
  const editModeIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-2.5 -2.5 24 24"
        width="24"
        fill="currentColor"
      >
        <path d="M12.238 5.472L3.2 14.51l-.591 2.016 1.975-.571 9.068-9.068-1.414-1.415zM13.78 3.93l1.414 1.414 1.318-1.318a.5.5 0 0 0 0-.707l-.708-.707a.5.5 0 0 0-.707 0L13.781 3.93zm3.439-2.732l.707.707a2.5 2.5 0 0 1 0 3.535L5.634 17.733l-4.22 1.22a1 1 0 0 1-1.237-1.241l1.248-4.255 12.26-12.26a2.5 2.5 0 0 1 3.535 0z"></path>
      </svg>
    );
  };

  const viewModeIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-2 -6 24 24"
        width="24"
        fill="currentColor"
      >
        <path d="M18 6c0-1.81-3.76-3.985-8.007-4C5.775 1.985 2 4.178 2 6c0 1.825 3.754 4.006 7.997 4C14.252 9.994 18 7.82 18 6zm-8 6c-5.042.007-10-2.686-10-6S4.984-.017 10 0c5.016.017 10 2.686 10 6s-4.958 5.993-10 6zm0-2a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
      </svg>
    );
  };

  const buttonClassName = inEditMode
    ? "btn btn-dark bg-dark "
    : "btn btn-light bg-dark";
  const buttonText = inEditMode ? "View mode" : "Edit mode";
  const icon = inEditMode ? viewModeIcon : editModeIcon;

  return (
    <button
      onClick={() => setInEditMode((prev) => !prev)}
      className={buttonClassName}
    >
      {icon()}
      {buttonText}
    </button>
  );
};
