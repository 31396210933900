import React from "react";
import { ConditionsBoxProps } from "../utils/interface/HSM";

const HSMConditionsBox = ({
  preconditions,
  setPreconditions,
  postconditions,
  setPostconditions,
  editing,
}: ConditionsBoxProps) => {
  if (!editing) {
    const preText =
      !preconditions || preconditions === "" ? "None declared" : preconditions;
    const postText =
      !postconditions || postconditions === ""
        ? "None declared"
        : postconditions;
    return (
      <div className="HSM-Conditions animatable">
        <div className="conditions-text">
          <h6>
            <b>Preconditions</b>
          </h6>

          <p className="precond-text border-bottom border-dark">{preText}</p>

          <h6>
            <b>Postconditions</b>
          </h6>

          <p>{postText}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="HSM-Conditions animatable">
        <div className="conditions-text">
          <h6>
            <b>Preconditions</b>
          </h6>

          <textarea
            onChange={(e) => setPreconditions(e.target.value)}
            value={preconditions}
            className="conditionsTextArea"
          />

          <h6>
            <b>Postconditions</b>
          </h6>

          <textarea
            onChange={(e) => setPostconditions(e.target.value)}
            value={postconditions}
            className="conditionsTextArea"
          />
        </div>
      </div>
    );
  }
};

export default HSMConditionsBox;
